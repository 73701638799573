.userdetails
    div
        b Name:
        br
        | #{user.realname}
    div
        b Berechtigung:
        br
        | #{PermissionsInv[user.permission]}
    div
        b Benutzer-ID:
        br
        | #{user.id}
    div
        b Benutzername:
        br
        | #{user.username}
    div
        b Mehrere gleichzeitige Logins verhindern:
        br
        | #{user.preventMultiLogin ? 'Ja' : 'Nein'}
    div
        b Ort:
        br
        | #{user.city}
    div
        b Feuerwehr:
        br
        | #{readableDepartments}
    div
        b Alarm Timeout:
        br
        | #{user.alertTimeout}
    div
        b E-Mail:
        br
        | #{user.email}
    div
        b Alarm E-Mail:
        br
        | #{user.alarmEmail}
    div
        b GPS-Tracking aktiviert:
        br
        | #{user.enableGPSTracking ? 'Ja' : 'Nein'}
    div
        b GPS Farbe :
        br
        | #{user.gpsColor}
    div
        b Status setzen:
        br
        | #{user.allowStatus ? 'Ja' : 'Nein'}
    div
        b Status Name:
        br
        | #{user.statusName}
    div
        b Darf GPS-Positionen sehen:
        br
        | #{user.viewGPSLocations ? 'Ja' : 'Nein'}
    if externalName
        div
            b Leitstellen-Zuordnung:
            br
            | #{externalName}
    div
        b Wasserkarte Token:
        br
        | #{user.wasserkarteToken}
    div
        b Objektdatenbank Token:
        br
        | #{user.objektdatenbankToken}
    div
        b PDF Konfiguration:
        br
        | #{user.pdfConfig}
    div
        b Login-Code:
        br
        | #{user.logincode}
