const $ = window.$;
const config = window.config;
const {getUserConfig, setUserConfig, resetUserConfig, getEnableGPSTracking} = require('../../main/login');
const {getDefaultConfig} = require('../../leaflet/parse-config');
const {createCrappyJson} = require('../../main/lib');

const template = require('./config.pug');

const fillConfigNode = function(node, parentNodeName) {
    $.each(node, (name, val) => {
        if (typeof val === 'object') {
            fillConfigNode(val, parentNodeName + name + '.');
        } else {
            let element = $('[data-form="' + parentNodeName + name + '"]');
            if (element.length) {
                if (element.prop("tagName").toLowerCase() === 'input' && element.attr('type') === 'checkbox') {
                    element.prop('checked', !!parseInt(val)).change();
                } else {
                    element.val(val);
                }
            }
        }
    });
};

const fillForm = function(configObject) {
    if (configObject !== undefined && configObject !== "") {
        try {
            fillConfigNode(configObject, "");
        } catch (e) {
            console.log(e);
        }
    }
};

const setToObjectpath = function(rootObject, objectPath, value) {
    if (value === null) {
        return;
    }

    let splittedPath = objectPath.split('.');
    let object = rootObject;
    let lastObjName = splittedPath.pop();
    if (splittedPath) {
        for (let i in splittedPath) {
            if (!object.hasOwnProperty(splittedPath[i])) {
                object[splittedPath[i]] = {};
            }
            object = object[splittedPath[i]];
        }
    }
    object[lastObjName] = value;
};

const saveCurrentConfig = function(forceReload = true) {
    let userConfig = {};
    $('*[data-form]').each((index, el) => {
        let element = $(el);
        let value = null;
        if (element.prop("tagName").toLowerCase() === 'input' && element.prop('type') === 'checkbox') {
            value = element.is(':checked') ? 1 : 0;
        } else if (element.val()) {
            value = element.val();
            if (element.data('form').endsWith('iw')) {
                value = encodeURIComponent(value.replace('https://', '').replace('http://', ''));
            } else if (element.data('form').endsWith('fgi')) {
                value = value.replace(' ', '-');
            }
            if (value === "") {
                return;
            }
        }

        setToObjectpath(userConfig, element.data('form'), value);
    });

    setUserConfig(userConfig, forceReload);
};

const start = function() {
    let dataLayersElement = $('#dataLayers');
    const dataLayersTemplate = dataLayersElement.html();
    dataLayersElement.html('');

    if (config.map.waterSources.enabled === false) {
        $('[data-form="dl:wts"]').closest('.form-group').remove();
    }

    $('#resetConfig').click(function() {
        if (confirm("Benutzereinstellungen auf Standard zurücksetzen?")) { // eslint-disable-line no-alert
            resetUserConfig();
        }
    });

    $('#applyConfig').click(function() {
        saveCurrentConfig();
    });

    $('#saveConfig').click(function() {
        saveCurrentConfig(false);
        document.location.reload();
    });

    (function() {

        $('#loadingScreen').show();
        let dataLayers = null;

        $.getJSON('/odb/', {
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        }).done(function (data) {
            $('#dataLayers').empty();
            if (data && data.length > 0) {
                dataLayers = data;
                for (let i in data) {
                    let dataLayer = data[i];
                    let entry = dataLayersTemplate;
                    entry = entry.replace("#caption#", dataLayer.name);
                    entry = entry.replace("#shortCode#", dataLayer.shortCode);
                    $('#dataLayers').append(entry);
                }
            } else {
                $('#dataLayers').remove();
            }

            fillForm(getDefaultConfig(dataLayers));
            fillForm(getUserConfig());

            /* Special-replacements */
            $('*[data-form][data-type="array"]').each(function() {
                $(this).val($(this).val().replace('-', ' '));
            });

            $('input[type=checkbox][data-toggle^=toggle]').bootstrapToggle();

            $('#loadingScreen').hide();
        });

    }());
};

module.exports = {
    template: () => template({
        enableGPSTracking: getEnableGPSTracking(),
        mapPresets: config.map.presets,
        activeConfig: createCrappyJson(getUserConfig())
    }),
    start
};