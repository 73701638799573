const $ = window.$;
const template = require("./search.pug");
const {getUserConfig, getDefaultCity, getDefaultState} = require("../../main/login.js");
const {authAjax, createDropDown, isMobileView, scrollDownOnFocus, createCrappyJson} = require("../../main/lib.js");

let showMobileSearch = function() {
    $('.page-2cols-2575').addClass("left--open");
};

let hideMobileSearch = function() {
    $('.page-2cols-2575').removeClass("left--open");
};

let start = function(mode) {
    $('.inputClear').click(function () {
        $(this).closest('.input-group').find('input').val("").focus();
    });

    var searchTypeButtons = $('#search-modeswitch a[data-searchtype]');

    searchTypeButtons.click(function () {
        searchTypeButtons.removeClass('active');
        $(this).addClass('active');
        $('div.kilometer, div.namensgut, div.streetNumber, div.hofname, div.odb').hide().find('input,select').prop('disabled', true);
        $('input[name="searchType"]').val($(this).data('searchtype'));
        switch ($(this).data('searchtype')) {
            case 'kilometer': $('div.kilometer').show().find('input,select').prop('disabled', false); break;
            case 'namensgut': $('div.namensgut').show().find('input,select').prop('disabled', false); break;
            case 'hofname':
                $('div.hofname').show().find('input,select').prop('disabled', false);
                $("select[name='hofnameState']").attr("required", true);
                $("select[name='hofnameState']").chosen({width: "100%"});
                break;
            case 'odb':
                $('div.odb').show().find('input,select').prop('disabled', false);
                $("select[name='odbCategory']").attr("required", true).chosen({width: "100%"});
                break;
            default: $('div.streetNumber').show().find('input,select').prop('disabled', false); break;
        }
    });

    $('.closeButton').click(() => hideMobileSearch());
    $('.showLeftCol').click(() => showMobileSearch());

    $('input.cityInput').val(getDefaultCity()).autocomplete({
        serviceUrl: function () {
            return '/autocomplete/address/?stateNumber=' + $('[name=state]').val();
        },
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
            $('input.streetInput').focus();
        }
    });

    $('input.streetInput').autocomplete({
        serviceUrl: function () {
            return '/autocomplete/address/?stateNumber=' + $('[name=state]').val() + '&city=' + $('input.cityInput').val();
        },
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
            this.classList.remove('notfound');
            $(this).autocomplete('setOptions', {triggerSelectOnValidInput: false});
            $('input.streetNumberInput').focus();
        },
        onHide: function() {
            $('#lat').val(0);
            $('#lng').val(0);
            this.classList.add('notfound');
            $(this).autocomplete('setOptions', {triggerSelectOnValidInput: true});
        }
    });

    $('input.streetNumberInput').autocomplete({
        serviceUrl: function () {
            return '/autocomplete/address/?stateNumber=' + $('[name=state]').val() + '&city=' + $('input.cityInput').val() + '&street=' + $('input.streetInput').val();
        },
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
            this.classList.remove('notfound');
            $(this).autocomplete('setOptions', {triggerSelectOnValidInput: false});
            $('button.search').focus();
        },
        onHide: function() {
            $('#lat').val(0);
            $('#lng').val(0);
            this.classList.add('notfound');
            $(this).autocomplete('setOptions', {triggerSelectOnValidInput: true});
        },
        minLength: 0
    });

    $('#oebb-autofill').click(() => {
        $('input.autoStreetInput').val('').focus();
        setTimeout(() => {
            $('input.autoStreetInput').val('ÖBB').focus();
        }, 300);
    });

    $('input.autoStreetInput').autocomplete({
        serviceUrl: '/autocomplete/street/',
        onSelect: function (suggestion) {
            $('#lat').val(0);
            $('#lng').val(0);
            this.classList.remove('notfound');
            $(this).val(suggestion.value);
            $('input.streetKmInput').val("").focus();
            $(this).autocomplete('setOptions', {triggerSelectOnValidInput: false});
        },
        minLength: 0,
        noSuggestionNotice: "Es konnte nichts gefunden werden!",
        showNoSuggestionNotice: true,
        onHide: function() {
            $('#lat').val(0);
            $('#lng').val(0);
            this.classList.add('notfound');
            $(this).autocomplete('setOptions', {triggerSelectOnValidInput: true});
        }
    });
    $('input.streetKmInput').autocomplete({
        serviceUrl: function () {
            return '/autocomplete/street/?street=' + $('input.autoStreetInput').val();
        },
        onSelect: function (suggestion) {
            this.classList.remove('notfound');
            $(this).val(suggestion.value);
            $('#lat').val(suggestion.data.lat);
            $('#lng').val(suggestion.data.lng);
            $(this).autocomplete('setOptions', {triggerSelectOnValidInput: false});
            $('button.search').focus();
        },
        minLength: 0,
        showOnFocus: true,
        noSuggestionNotice: "Es konnte nichts gefunden werden!",
        showNoSuggestionNotice: true,
        onHide: function() {
            $('#lat').val(0);
            $('#lng').val(0);
            this.classList.add('notfound');
            $(this).autocomplete('setOptions', {triggerSelectOnValidInput: true});
        }
    });

    let namensgutCitySelect = $('select.namensgutCitySelect');

    $('input.namensgutNameInput').autocomplete({
        serviceUrl: '/autocomplete/namensgut/',
        minChars: 1,
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
            $.getJSON('/autocomplete/namensgut/?name=' + suggestion.value, function(data) {
                namensgutCitySelect.find('option').remove();
                if (data.suggestions.length > 1) {
                    namensgutCitySelect.append('<option value="">Bitte wählen</option>');
                }
                $(data.suggestions).each(function (index, sug) {
                    let newOption = $('<option />');
                    newOption.text(sug.value);
                    newOption.data({lat: sug.data.lat, lng: sug.data.lng});
                    namensgutCitySelect.append(newOption);
                });
            });
        }
    });

    $('input.hofnameCityInput').val(getDefaultCity()).autocomplete({
        serviceUrl: function () {
            return '/autocomplete/hofname/?stateNumber=' + $("select[name='hofnameState']").val();
        },
        paramName: 'gemeinde',
        minChars: 1,
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
            $('input.hofnameNameInput').focus();
        }
    });

    $('input.hofnameNameInput').autocomplete({
        serviceUrl: function () {
            return '/autocomplete/hofname/?stateNumber=' + $("select[name='hofnameState']").val() + '&gemeinde=' + $('input.hofnameCityInput').val();
        },
        paramName: 'hofname',
        minChars: 1,
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
            this.classList.remove('notfound');
            $('#lat').val(0);
            $('#lng').val(0);
            authAjax('/geocode/hofname/?stateNumber=' + $("select[name='hofnameState']").val() + '&gemeinde=' + $('input.hofnameCityInput').val() + '&hofname=' + $('input.hofnameNameInput').val(), function (hofnameGeocode) {
                $('#lat').val(hofnameGeocode.lat);
                $('#lng').val(hofnameGeocode.lng);
                $('input.hofnameStreet').val(hofnameGeocode.strassenname);
                $('input.hofnameStreetNumber').val(hofnameGeocode.hausnummer);
            });
            $('button.search').focus();
        },
        noSuggestionNotice: "Es konnte nichts gefunden werden!",
        showNoSuggestionNotice: true,
        onHide: function() {
            $('#lat').val(0);
            $('#lng').val(0);
            this.classList.add('notfound');
        }
    });

    $('input.odbNameInput').autocomplete({
        serviceUrl: function () {
            return '/autocomplete/odb/' + "?category=" + $("select[name='odbCategory']").val() + "&subcategory=" + $("select[name='odbSubcategory']").val();
        },
        paramName: 'query',
        minChars: 3,
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
            this.classList.remove('notfound');
            $('#lat').val(suggestion.data.lat || 0);
            $('#lng').val(suggestion.data.lng || 0);
            $('input.odbMatchCategory').val(suggestion.data.category || '');
            $('button.search').focus();
        },
        noSuggestionNotice: "Es konnte nichts gefunden werden!",
        showNoSuggestionNotice: true,
        onHide: function() {
            $('#lat').val(0);
            $('#lng').val(0);
            $('input.odbMatchCategory').val('');
            this.classList.add('notfound');
        }
    });

    scrollDownOnFocus($('.page-2cols-2575 .left'));

    $("form[name='search']").submit(function (e) {
        e.preventDefault();
        var query = "/leaflet/?";
        let lat = $('#lat').val();
        let lng = $('#lng').val();
        let selectedCity;
        switch ($('input[name="searchType"]').val()) {
            case 'kilometer':
                if (lng === "0") {
                    // eslint-disable-next-line no-alert
                    alert("Nicht gefunden, bitte wählen Sie die Einträge aus den Suchvorschlägen aus.");
                    return;
                }
                query += "lng" + "=" + lng + "&";
                query += "lat" + "=" + lat + "&";
                query += "street" + "=" + $('input.autoStreetInput').val() + "&";
                query += "streetNo" + "=" + $('input.streetKmInput').val() + "&";
                break;
            case 'namensgut':
                selectedCity = namensgutCitySelect.find('option:selected');
                query += "lng" + "=" + selectedCity.data('lng') + "&";
                query += "lat" + "=" + selectedCity.data('lat') + "&";
                query += "street" + "=" + $('input.namensgutNameInput').val() + "&";
                query += "city" + "=" + namensgutCitySelect.val() + "&";
                query += "streetNo" + "=" + "ÖK" + "&";
                break;
            case 'hofname':
                query += "lng" + "=" + lng + "&";
                query += "lat" + "=" + lat + "&";
                query += "street" + "=" + $('input.hofnameNameInput').val() + " - " + $('input.hofnameStreet').val() + "&";
                query += "city" + "=" + $('input.hofnameCityInput').val() + "&";
                query += "streetNo" + "=" + $('input.hofnameStreetNumber').val() + "&";
                break;
            case 'odb':
                query += "lng" + "=" + lng + "&";
                query += "lat" + "=" + lat + "&";
                query += "name" + "=" + $('input.odbNameInput').val() + "&";
                query += "category" + "=" + $('input.odbMatchCategory').val() + "&";
                break;
            default:
                query += "state" + "=" + $('[name=state]').val() + "&";
                query += "street" + "=" + $('input.streetInput').val() + "&";
                query += "city" + "=" + $('input.cityInput').val() + "&";
                query += "streetNo" + "=" + $('input.streetNumberInput').val() + "&";
                break;
        }
        query += "search=1&";

        if (mode === 'alarm') {
            query += "addAlarm=1&";
        }

        var userConfig = getUserConfig();
        if (userConfig !== "") {
            query += "c=" + createCrappyJson(userConfig) + "&";
        }

        $("#search-map").attr("src", query);
        hideMobileSearch();

    });

    if (isMobileView()) {
        showMobileSearch();
    }

    $(document).ready(function () {
        authAjax("/autocomplete/state", function (statesData) {
            const defaultState = getDefaultState() || statesData.default;
            $("select[name='state']").replaceWith(
                createDropDown("state", statesData.suggestions, defaultState)
            );
            $("select[name='state']").attr("required", true).chosen({width: "100%"});
            $("select[name='hofnameState']").replaceWith(
                createDropDown("hofnameState", statesData.suggestions, defaultState)
            );
        });

        authAjax("/autocomplete/odb/layers", function (odbLayers) {
            const suggestions = odbLayers.map((cat, i) => {
                return {
                    value: i,
                    data: cat.label
                };
            });

            $("select[name='odbCategory']").replaceWith(
                createDropDown("odbCategory", suggestions, suggestions[0] && suggestions[0].value)
            );
            $("select[name='odbSubcategory']").prop('disabled', true);
            $("select[name='odbCategory']").change(() => {
                $('input.odbNameInput').autocomplete("clearCache");
                const sel = $("select[name='odbCategory']").val();

                $("select[name='odbSubcategory']").chosen("destroy");

                const subsuggestions = [];
                subsuggestions.push({value: '', data: 'Alle'});
                if (odbLayers[sel].sub) {
                    odbLayers[sel].sub.forEach((cat, i) => {
                        subsuggestions.push({
                            value: i,
                            data: cat.label
                        });
                    });
                }
                $("select[name='odbSubcategory']").replaceWith(
                    createDropDown("odbSubcategory", subsuggestions, subsuggestions[0].value)
                );
                if (subsuggestions.length > 1) {
                    $(".odbSubcategoryGroup").show();
                    $("select[name='odbSubcategory']").prop('disabled', false);
                } else {
                    $(".odbSubcategoryGroup").hide();
                    $("select[name='odbSubcategory']").prop('disabled', true);
                }
                $("select[name='odbSubcategory']").chosen({width: "100%"});
            });
            $("select[name='odbSubcategory']").change(() => {
                $('input.odbNameInput').autocomplete("clearCache");
            });
        });
    });
};

module.exports = {
    default: {
        template,
        start: () => start()
    },
    alarm: {
        template: () => template({mode: 'alarm'}),
        start: () => start('alarm')
    }
};