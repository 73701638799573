const $ = window.$;

$.fn.extend({
    chosenTouch: function() {
        return this.each(function() {
            $(this).chosen();

            let container = $(this).parent().find('.chosen-container-multi');
            $('.search-choice-close, .chosen-choices', container).on('touchend', function () {
                $(this).click();
            });
        });
    }
});