form.useredit
    input(type="hidden" name="id" value=user.id)
    div
        b Name:
        br
        input(type="text" name="realname" value=user.realname)
    div
        b Berechtigung:
        br
        select(name="permission" size="1")
            each p, index in Permissions
                option(value=p selected=(user.permission === p)) #{index}
    div
        b Benutzername:
        br
        input(type="text" name="username" value=user.username)
    div
        b E-Mail:
        br
        input(type="email" name="email" value=user.email)
    div
        b Alarm E-Mail:
        br
        input(type="text" name="alarmEmail" value=user.alarmEmail)
    div
        b Feuerwehr:
        br
        select.chosen(multiple="" name="departmentId")
            each d in departmentList
                option(value=d.id selected=user.departments.filter(function (userDep) {return userDep.id === d.id}).length > 0) #{d.name}
    div
        b Ort:
        br
        select.chosen(name="cityId")
            each c in cityList
                option(value=c.id selected=user.cityId===c.id) #{c.name}
    div
        b Login-Code:
        br
        input(type="text" name="logincode" value=user.logincode)
        div.warning ACHTUNG: Soll nicht mehr verwendet werden.
    div
        b Passwort:
        br
        input(type="password" name="password" value=user.password)
        span(id="togglePassword")
            i.fa.fa-eye-slash
    div
        b Mehrere gleichzeitige Logins verhindern:
        br
        input(type="checkbox" name="preventMultiLogin" checked=(user.preventMultiLogin==1))
    div
        b Alarm Timeout:
        br
        input(type="text" name="alertTimeout" value=user.alertTimeout)
    div
        b GPS-Tracking aktiviert:
        br
        input(type="checkbox" name="enableGPSTracking" checked=(user.enableGPSTracking==1))
    div
        b GPS Farbe:
        br
        input(type="color" name="gpsColor" value=user.gpsColor)
    div
        b Status setzen:
        br
        input(type="checkbox" name="allowStatus" checked=(user.allowStatus==1))
    div
        b Status Name:
        br
        input(type="text" name="statusName" value=user.statusName)
    div
        b Darf GPS-Positionen sehen:
        br
        input(type="checkbox" name="viewGPSLocations" checked=(user.viewGPSLocations==1))
    -if(vars.els && vars.els.enabled)
        div
            b Leitstellen-Zuordnung:
            br
            select.chosen(name="externalResource")
                option(value='') -
                each r in externalResourcesList
                    option(data-id=r.id data-type=r.type selected=(user.externalId==r.id && user.externalType==r.type)) #{r.name}
    div
        b Wasserkarte Token:
        br
        input(type="text" name="wasserkarteToken" value=user.wasserkarteToken)
    div
        b Objektdatenbank Token:
        br
        input(type="text" name="objektdatenbankToken" value=user.objektdatenbankToken)
    div
        b PDF Konfiguration:
        br
        input(type="text" name="pdfConfig" value=user.pdfConfig)
