div.admin
    h2
        | #{vars.projectName} Benutzer
        a#addNewLink.btn.btn-success.btn-xs(href='#navAdmin', style='margin-left: 15px;')
            i.fa.fa-plus
            |  Neuen Benutzer hinzufügen
        | 
        span#loadingLeitstelle.btn.btn-xs.btn-grey(style='display: none;')
            i.fa.fa-spinner.fa-pulse
            |  Daten aus Leitstelle werden geladen
    form#listFilter
        .form-group
            .input-group
                span.input-group-addon
                    i.fa.fa-search
                input#userlist-search.form-control(type='text', placeholder='Suche...')
    #listContainer
        table#userlist-table.listing
            thead
                tr#headerRow
                    th(style='width: 250px') Name
                    th(style='width: 200px') Ort
                    th=vars.departmentCaption
                    th.text-center(style='width: 90px')
                        | Einsatz
                        br
                        | Timeout
                    th.text-center(style='width: 80px')
                        | Alarm
                        br
                        | E-Mail
                    th.text-center(style='width: 90px')
                        | Wasser
                        br
                        | Karte
                    th.text-center(style='width: 90px')
                        | Objekt
                        br
                        | Datenbank
                    th(style='width: 120px')
            tbody
    #modal-preset.modal.fade(tabindex='-1', role='dialog')
        .modal-dialog(role='document')
            .modal-content
                .modal-header
                    button.close(type='button', data-dismiss='modal', aria-label='Close')
                        span(aria-hidden='true') ×
                    h4.modal-title Titel
                .modal-body Inhalt
                .modal-footer
                    button.btn.btn-success(type='button') Speichern
    #loadingScreen(style='display: none;')
        i.fa.fa-cog.fa-spin.fa-3x.fa-fw
