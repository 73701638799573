const {registerUpdateNotificationHandler} = require('../../main/socket.js');
const {getUserConfig, getAlertTimeout} = require('../../main/login');
const {authAjax, createCrappyJson} = require("../../main/lib.js");
const { createElement: h, h1, iframe, div } = require('preact-hyperscript');
const { render, Component } = require('preact');
const objectHash = require('object-hash');
const notification = require('../../modules/notification/notification');

let updateHandler = false;

const composeIframeUrl = function(alert) {
    const userConfig = getUserConfig();
    // eslint-disable-next-line new-cap
    const hash = objectHash.MD5(alert);
    return `/leaflet/?alertId=${alert.id}&c=${createCrappyJson(userConfig)}&hash=${hash}`;
};

class Mosaic extends Component {
    constructor(props) {
        super(props);
        this.state = { items: [] };
    }

    componentDidMount() {
        if (!updateHandler) {
            updateHandler = (updateAlertId) => this.update(updateAlertId);
            registerUpdateNotificationHandler(updateHandler);
            const userAlertTimeout = getAlertTimeout();
            const alertRefreshTimeout = Math.min(userAlertTimeout, 15);
            setInterval(updateHandler, alertRefreshTimeout * 60 * 1000);
        }
        this.update();
    }

    update(updateAlertId = 0) {
        const userConfig = getUserConfig();
        const mosaicConfig = Object.assign({}, window.config.map.userconfig.standard.mo, userConfig.mo);
        const limit = mosaicConfig.wd || 6;
        const timeout = mosaicConfig.to || 0;

        if (document.querySelector('.mosaic') === null) {
            return;
        }

        const limitParam = "limit=" + limit;
        const timeoutParam = ((parseInt(timeout) > 0) ? "&timeout=" + timeout : "");
        authAjax("/list?" + limitParam + timeoutParam, (data) => {
            const items = data.map((i) => ({src: composeIframeUrl(i), id: i.id}));
            if (items.length > 0) {
                items.forEach(i => {
                    notification.resetNotification(i.id);
                    if (i.id === updateAlertId) {
                        notification.notifyShort();
                    }
                });
                this.setState({items});
            } else {
                if (userConfig.iw) {
                    this.setState({items: [{src: 'https://' + userConfig.iw, id: 'iw'}]});
                } else {
                    this.setState({items: []});
                }

            }
        });
    }

    render() {
        if (this.state.items.length > 0) {
            return div({className: `mosaic mosaic--${this.state.items.length} fullscreen-area`},
                this.state.items.map(i => iframe({src: i.src, className: 'item', key: i.id}))
            );
        } else {
            return div({className: `mosaic`}, h1({}, 'Aktuell keine Einsätze'));
        }

    }
}

const start = function({container}) {
    render(
        h(Mosaic, {}),
        container.get(0)
    );
};

module.exports = {
    template: () => "",
    start
};