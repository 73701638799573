const $ = window.$;
const {authAjax, getHashParameterByName, createDropDown, getAppConfig} = require('../../main/lib.js');
const {getPermission, Permissions, getDefaultCity, getDefaultState, getLoginName} = require('../../main/login.js');
const vars = getAppConfig();

const template = require('./add-alarm.pug');

const start = function() {
    $('.inputClear').click(function () {
        $(this).closest('.input-group').find('input:not([readonly])').val("").focus();
    });

    if (getPermission() !== Permissions.Admin) {
        $('[name=official]').closest('.form-group').hide();
        $('[name=official]').val(getLoginName());
        $('[name=description]').closest('.form-group').hide();
        $('[name=alarmType]').closest('.form-group').hide();
    }
    if (getHashParameterByName("lat") !== "" && getHashParameterByName("lng") !== "") {
        $('[name=lat]').val(getHashParameterByName("lat"));
        $('[name=lng]').val(getHashParameterByName("lng"));
        if (getHashParameterByName("state") !== "") {
            // is done after loading dropdown content
        } else {
            $('[name=state]').closest('.form-group').remove();
        }
        if (getHashParameterByName("street") !== "") {
            $('[name=street]').val(getHashParameterByName("street")).attr('readonly', true);
        } else {
            $('[name=street]').closest('.form-group').remove();
        }
        if (getHashParameterByName("streetNumber") !== "") {
            $('[name=streetNumber]').val(getHashParameterByName("streetNumber")).attr('readonly', true);
        } else {
            $('[name=streetNumber]').closest('.form-group').remove();
        }
        if (getHashParameterByName("city") !== "") {
            window.setTimeout(function() {
                $('[name=place]').val(getHashParameterByName("city")).attr('readonly', true);
            }, 1);
        } else {
            $('[name=place]').closest('.form-group').remove();
        }

    } else {
        $('[name=lat]').closest('.form-group').hide();
        $('[name=lng]').closest('.form-group').hide();
    }

    if (getHashParameterByName("noCoords") === "1") {
        $('[name=state]').closest('.form-group').remove();
        $('[name=street]').closest('.form-group').remove();
        $('[name=streetNumber]').closest('.form-group').remove();
        $('[name=place]').closest('.form-group').remove();
    }

    var officials = [
        'LAWZ Salzburg', 'BAWZ Tennengau', 'BAWZ Pinzgau', 'BAWZ Lungau', 'BAWZ Pongau', 'BAWZ', 'Florian'
    ];

    var types = [
        'BMA ALARM',
        'BMA FEHLALARM',
        'BMA TÄUSCHUNG',
        'BRANDVERDACHT',
        'BRAND CONTAINER, MÜLL',
        'BRAND DACHSTUHL',
        'BRAND ELEKTR. ANLAGE',
        'BRAND FLUR, WALD',
        'BRAND GARAGE, WERKSTATT',
        'BRAND GEWERBE, INDUSTRIE',
        'BRAND SPITAL, HEIM',
        'BRAND HEIZANLAGE, KAMIN',
        'BRAND HOTEL, GASTSTÄTTE',
        'BRAND KELLER',
        'BRAND FAHRZEUG',
        'BRAND ZIMMER, KÜCHE',
        'BRAND LANDWIRT. OBJEKT',
        'BRAND SCHULE, KINDERGARTEN',
        'BRAND SILO',
        'BRAND SONSTIGER',
        'KEIN FEUERWEHREINSATZ MEHR ERFORDERLICH',
        'BRAND IM TUNNEL',
        'BRAND VERANSTALTUNGSRAUM',
        'BRAND WOHNHAUS, -ANLAGE',
        'GEFAHRGUTEINSATZ',
        'STRAHLENSCHUTZEINSATZ',
        'PERSON ABGESTÜRZT',
        'ARBEITSUNFALL',
        'PERSON IN SILO, GÄRKELLER',
        'PERSON IN GEWÄSSER',
        'PERSONENRETTUNG AUS HÖHE',
        'KEIN FEUERWEHREINSATZ MEHR ERFORDERLICH',
        'PERSON IM STROMKREIS',
        'VU VERLETZTE PERSON',
        'PERSON VERSCHÜTTET',
        'ÜBERÖRTLICH BRAND',
        'ÜBERÖRTLICH MENSCHENRETTUNG',
        'ÜBERÖRTLICH TECHNIK',
        'ÜBERÖRTLICH VU',
        'BRAND AUF AUTOBAHN',
        'SONST. EINSATZ AUTOBAHN',
        'KEIN FEUERWEHREINSATZ MEHR ERFORDERLICH',
        'VU AUF AUTOBAHN',
        'FLUGZEUGABSTURZ',
        'HUBSCHRAUBERABSTURZ',
        'NOTLANDUNG LUFTFAHRZEUG',
        'KEIN FEUERWEHREINSATZ MEHR ERFORDERLICH',
        'UNFALL MIT LUFTFAHRZEUG',
        'FLUTWELLENALARM',
        'BRAND IM TUNNEL',
        'SONST. EINSATZ IM TUNNEL',
        'KEIN FEUERWEHREINSATZ MEHR ERFORDERLICH',
        'VU IM TUNNEL',
        'PERSONENRETTUNG MIT BOOT',
        'KEIN FEUERWEHREINSATZ MEHR ERFORDERLICH',
        'TAUCHEINSATZ',
        'AUFRÄUMEN NACH VU',
        'BAUM UMGESTÜRZT',
        'FAHRZEUGBERGUNG',
        'GASGEBRECHEN',
        'GEFAHRGUTEINSATZ',
        'BEISTELLUNG VON GERÄTEN',
        'LAWINENABGANG',
        'PERSONENRETTUNG AUS LIFT',
        'MURE, ERDRUTSCH',
        'ÖLEINSATZ',
        'SCHNEELAST',
        'SONST. TECHN. EINSATZ',
        'KEIN FEUERWEHREINSATZ MEHR ERFORDERLICH',
        'UNWETTER, STURM',
        'SUCHAKTION',
        'SERVICE-, TEST-TEXT',
        'TIERRETTUNG',
        'TÜRÖFFNUNG NOTFALL',
        'AUSPUMPEN, WASSERSCHADEN',
        'WASSERTRANSPORT',
        'INSEKTEN, REPTILIEN',
        'VU EINGEKL. PERSON',
        'VU MIT EISENBAHN',
        'KEIN FEUERWEHREINSATZ MEHR ERFORDERLICH',
        'VU MIT GEFAHRGUT',
        'VU MEHRERE KFZ',
        'WESPENEINSATZ',
        'ÜBUNG',
        'TESTEINSATZ'
    ];

    $('input[name=official]').autocomplete({
        lookup: officials,
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
        }
    });
    $('input[name=type]').autocomplete({
        lookup: types,
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
        }
    });
    $('input[name=place]').autocomplete({
        serviceUrl: function() { return '/autocomplete/address/?stateNumber=' + $('[name=state]').val(); },
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
        }
    });
    $('input[name=street]').autocomplete({
        serviceUrl: function() { return '/autocomplete/address/?stateNumber=' + $('[name=state]').val() + '&city=' + $('input[name=place]').val(); },
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
        }
    });

    $('input[name=streetNumber]').autocomplete({
        serviceUrl: function() { return '/autocomplete/address/?stateNumber=' + $('[name=state]').val() + '&city=' + $('input[name=place]').val() + '&street=' + $('input[name=street]').val(); },
        onSelect: function (suggestion) {
            $(this).val(suggestion.value);
            $('input[name=streetNumber]').focus();
        },
        minLength: 0
    });

    $('input[name=street], input[name=place]').attr('autocomplete', 'false');
    $('input[name=place]').val(getDefaultCity());

    $(document).ready(function() {
        authAjax("/departments", function (departmentData) {
            $("select[name='alerted']").replaceWith(
                createDropDown("alerted", departmentData, [])
            );
            $("select[name='alerted']").attr("required", true);
            $("select[name='alerted']").chosenTouch();
        });
    });

    $(document).ready(function() {
        authAjax("/autocomplete/state", function (statesData) {
            if (getHashParameterByName("state") === "") {
                const defaultState = getDefaultState() || statesData.default;
                $("select[name='state']").replaceWith(
                    createDropDown("state", statesData.suggestions, defaultState)
                );
                $("select[name='state']").attr("required", true);
                $("select[name='state']").chosenTouch();
            } else {
                const sel = $("select[name='state']")[0];
                // eslint-disable-next-line no-unused-expressions
                statesData && statesData.suggestions && statesData.suggestions.forEach(state => {
                    const option = document.createElement("option");
                    option.text = state.data;
                    option.value = state.value;
                    sel.add(option, sel[0]);
                });
                $(sel).val(getHashParameterByName("state")).attr('readonly', true);
            }
        });
    });
};

module.exports = {
    start,
    template: () => template({vars})
};