const nativeApp = require('../../modules/native-app/native-app.js');

const template = require('./about.pug');

const start = () => {};

module.exports = {
    template: () => template({
        isNativeApp: nativeApp.isActive()
    }),
    start
};