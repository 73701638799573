.container
    script(src='/libs/jquery/jquery.autocomplete.js')
    form.addAlarm(name='addAlarm', action='/addAlarm', method='POST', autocomplete='off')
        h1.margin-bottom-0 Einsatz anlegen
        h3.margin-top-0.margin-bottom-15 2. Einsatzdaten eingeben
        div.form-group
            label Alarm durch:
            .input-group
                input.form-control(type='text', name='official')
                .input-group-addon.inputClear
                    i.fa.fa-times
        div.form-group
            label Alarmtyp:
            .input-group
                input.form-control(type='text', name='type', required='required')
                .input-group-addon.inputClear
                    i.fa.fa-times
            small.form-text.text-muted (z.B.: Übung Brand Küche)
        div.form-group
            label Bundesland
            select.form-control(name='state')

        div.form-group
            label Gemeinde:
            .input-group
                input.form-control(type='text', name='place', required='required', autocomplete='off')
                .input-group-addon.inputClear
                    i.fa.fa-times
            small.form-text.text-muted (z.B.: Salzburg)
        div.form-group
            label Ortschaft oder Straße:
            .input-group
                input.form-control(type='text', name='street', required='required', autocomplete='off')
                .input-group-addon.inputClear
                    i.fa.fa-times
            small.form-text.text-muted (z.B.: Getreidegasse)
        div.form-group
            label Hausnummer:
            .input-group
                input.form-control(type='text', name='streetNumber', required='required', autocomplete='off')
                .input-group-addon.inputClear
                    i.fa.fa-times
            small.form-text.text-muted (z.B.: 1)
        div.form-group
            label Latitude:
            input.form-control(type='text', readonly='readonly', name='lat')
        div.form-group
            label Longitude:
            input.form-control(type='text', readonly='readonly', name='lng')
        div.form-group
            label Beschreibung:
            .input-group
                input.form-control(type='text', name='description')
                .input-group-addon.inputClear
                    i.fa.fa-times
        div.form-group
            label=vars.alertedDepartmentCaption
            div
                select.form-control(name='alerted', data-allow-clear='true')
        div.form-group
            label Alarmstufe:
            .input-group
                input.form-control(type='text', name='alertLevel')
                .input-group-addon.inputClear
                    i.fa.fa-times
            small.form-text.text-muted (0 bis 4 möglich)
        div.form-group
            label Zusatz für Adresse:
            .input-group
                input.form-control(type='text', name='additionalAddressInfo')
                .input-group-addon.inputClear
                    i.fa.fa-times
            small.form-text.text-muted (z.B.: 1 Stock oder Test von Max Muster)
        div.form-group
            label Typ des Eintrags:
            select.form-control(name='alarmType')
                option(value='alarm') Alarm
                option(value='userDefault') Benutzer-Standard
                option(value='demo') Demo-Einsatz
        button.btn.btn-grey.search Einsatz anlegen
