.container.scrollContainer
    h2 Service und Information:
    p
        a(href='http://feuerwehreinsatz.info') Feuerwehreinsatz.info ist ein Service vom a(href='http://team122.at') team122.at
        br
        h3 Kontakt für Anfragen und Support:        
        p
        | 		E-Mail:
        a(href='mailto:info@122.co.at')
        |       info@122.co.at
        br
        | 		Telefon: 06604 122 122
        br
        | 		Supportteam: Andreas Rauchenbacher (FF St. Johann/Pg.) Lukas Fritzenwanger (FF Uttendorf), Stefan Hofer (FF Saalfelden) und Stefan Vötter (FF Kuchl)
        br
        br
    h3 Supportunterlagen, sowie Fragen und Antworten:
    p
        a(href='http://team122.at') team122.at
        |       Support Unterlagen, Fragen und Antworten team122.at
        br
        a(href='http://support.feuerwehreinsatz.info/') support.feuerwehreinsatz.info
        |       Support Unterlagen, Fragen und Antworten feuerwehreinsatz.info
        br
    h3 Vision:
    p
        a(href='http://feuerwehreinsatz.info') Feuerwehreinsatz.info
        |  stellt in Echtzeit die notwendigen Informationen bei Alarmeinsätzen für Feuerwehren in Salzburg zur Verfügung. Der Einsatzort kann so schneller gefunden werden.
    h3 Mission:
    p
        | Die Software wird den Feuerwehren in Salzburg kostenfrei zur Verfügung gestellt. Das System ist einfach zu implementieren und arbeitet einfach und zuverlässig. Es ist sowohl im Betrieb als auch in der Wartung kostengünstig. feuerwehreinsatz.info ist eine Open Source-Lösung.
    h3 Werte:
    p
        | Frei nach dem Gedanken:	retten – schützen – löschen – bergen
    - if (isNativeApp === false)
        h3 Download als App
        br
        h4 Vorteile gegenüber Webversion:
        p
            ul
                li Größere Bildschirmfläche (da es keine Browserzeile gibt)
                li Benutzerkonfiguration wird automatisch gespeichert
        p
            | Nach der Installation werdet ihr nach dem Logincode gefragt:
            br
            | 		Logincode: Die Zugangsdaten kann jede Feuerwehr in Salzburg beim Support Team anfordern.
            br
            br
        h4 Vorschlag zur Vorgehensweise für besseren Datenschutz:
        p
            | Es gibt jemanden in der Feuerwehr der den Code kennt und jene Mitglieder die die App haben wollen kommen zu diesem Mitglied und lassen sich den Logincode auf ihrem Handy eingeben.
            | Damit wird die Weitergabe des Logincodes unterbunden.
            | Im Prinzip läuft das also ähnlich wie mit der Funkrufempfänger Ausgabe bzw. die Programmierung der RICs
            br
        h4 Apps können hier für Google und Apple Endgeräte heruntergeladen werden:
        p
            .row
                .col-md-2.col-xs-4
                    a(target='_blank', href='https://geo.itunes.apple.com/at/app/feuerwehreinsatz.info/id1083837106?mt=8')
                        img(alt='Get it on Apple App Store', style='width: 100%; height: auto;', src='https://linkmaker.itunes.apple.com/images/badges/de-de/badge_appstore-lrg.svg')
                .col-md-2.col-xs-4
                    a(target='_blank', href='https://play.google.com/store/apps/details?id=at.fwei.app&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1')
                        img(alt='Get it on Google Play', style='width: 100%; height: auto;', src='https://play.google.com/intl/en_us/badges/images/generic/de-play-badge.png')
                .col-md-8.col-xs-4
            br
    h2 Projektteam
    p.about-text
        img(src='/images/emanuel_joebstl.jpg', width='100px', height='138px', style='float: right;')
        br
        b Emanuel Jöbstl
        br
        |            Freier Programmierer
        br
        |            Programmierung und Umsetzung
        br
        a(href='mailto:emanuel.joebstl@gmail.com') emanuel.joebstl@gmail.com
        br
    p.about-text
        img(src='/images/andreas_rauchenbacher.jpg', width='100px', height='138px', style='float: right;')
        br
        b LM Andreas Rauchenbacher
        br
        |            Feuerwehr St. Johann im Pongau
        br
        |            Entwicklung und Support
        br
        a(href='mailto:info@rauchenbacher.com') info@rauchenbacher.com
        br
    p.about-text
        img(src='/images/stefan_hofer.jpg', width='100px', height='138px', style='float: right;')
        br
        b OLM Stefan Hofer
        br
        | 			Feuerwehr Saalfelden
        br
        | 			Entwicklung und Support
        br
        a(href='mailto:shofer.privat@gmail.com') shofer.privat@gmail.com
        br
    p.about-text
        img(src='/images/stefan_voetter.jpg', width='100px', height='138px', style='float: right;')
        br
        b HV Stefan Vötter
        br
        | 			Feuerwehr Kuchl
        br
        | 			Projektleitung und Support
        br
        a(href='mailto:stefan@voetter.net') stefan@voetter.net
        br
    h2 Bei dem Projekt haben unterstützt:
    p
        b Fachliche Beratung:
        br
        br
        a(target='_blank', href='http://www.salzburg.gv.at/ls-raumordnung-sagis', style='float: left; margin-right: 20px; margin-top: -3px;')
            img(src='/images/landsalzburg_logo.jpg', alt='salzburg.gv.at', style='border: none;', width='140px', height='70px')
        b Land Salzburg
        br
        | 			Ref. Landesplanung und SAGIS
        br
        | 			Frau Michaela Rinnerberger
        br
        br
        br
        a(target='_blank', href='http://www.feuerwehrverband-salzburg.at/', style='float: left; margin-right: 20px; margin-top: -6px;')
            img(src='/images/feuerwehrverband_logo.jpg', alt='feuerwehrverband-salzburg.at/', style='border: none;', width='140px', height='70px')
        b Landesfeuerwehrverband Salzburg
        br
        | 			Landesalarm und Warnzentrale
        br
        | 			Herr OBI Markus Neumayr
        br
        br
    h2 Datenschutz
    p
        | Entsprechend den Bestimmungen des Datenschutzes wird ausdrücklich darauf hingewiesen, dass in Erfüllung der jeweiligen Anfragen: Namen, Adressen, Telefon, E-Mail-Adressen, Logos und Markenzeichen, sowie sonstige für den Einsatz relevante Daten des Anwenders (Feuerwehr) zwecks automatisationsunterstützter Datenverarbeitung auf einem Datenträger gespeichert werden.
        br
        | 			Einsatzdaten werden vertraulich behandelt und nicht an Dritte weitergegeben. Ebenso verpflichten sich die Dienstleister, alle Daten und Informationen im Zusammenhang mit der Anfragendurchführung vertraulich zu behandeln.
        br
        | 			Die Daten können jederzeit durch die Benutzer selbstständig gelöscht werden. Es besteht jederzeit die Möglichkeit mittels einer E-Mail an info@122.co.at die Löschung Ihrer Daten zu beauftragen. Bitte führen Sie in dieser E-Mail den Namen, die Login-Daten und das Passwort für Ihren Account an.
        br
    h2 Impressum
    p
        b           Diese Webseite wird vom gemeinnützigen Verein "Team122.at" für die Freiwilligen Feuerwehr Kuchl, die Freiwillige Feuerwehr Saalfelden und die Freiwillige Feuerwehr St. Johann im Pongau betrieben.
        br
        br
        b  			Betreiber der Webseite:
        br
        | 			Verein: Team122.at
        br
        | 			Kellau 198
        br
        | 			AT- 5431 Kuchl/Salzburg
        br
        | 			info@122.co.at
        br
        | 			+43 6604 122 122
        br
        | 			Rechtsform: Verein
        br
        | 			Vereinssitz: Kuchl
        br
        | 			ZVR-Zahl 1080728609
        br
        |           Behörde gem. ECG (E-Commerce Gesetz): Bezirkshauptmannschaft Hallein
        br
    h2 Nutzungsbedingungen für feuerwehreinsatz.info
    p
        | 1. Haftung für Inhalte und Ausfälle des Angebots
        br
        | 			Der Betreiber haftet nicht für die Verfügbarkeit des Angebots. Für Schäden, die dem Nutzer durch die Nutzung des Angebots entstehen, haftet der Betreiber nur in soweit, wie sie vom Betreiber auf Grund grober Fahrlässigkeit oder Vorsatz verursacht werden.
        br
        br
        | 			2. Sorgfaltspflichten des Nutzers
        br
        | 			Der Nutzer erhält nach der Anmeldung ein Kennwort, mit dem es ihm möglich ist, auf die Funktionen zuzugreifen (Log-In). Er verpflichtet sich dazu, dieses vertraulich zu behandeln und insbesondere Dritten (außerhalb der eigenen Feuerwehr) nicht zur Kenntnis zu geben. Soweit er Anhaltspunkte dafür hat, dass Dritte in den Besitz des Passwortes gelangt sein könnten, hat er dem Betreiber dies unverzüglich mitzuteilen.
        br
        br
        | 			3.Ordentliches Kündigungsrecht
        br
        | 			Sowohl der Nutzer als auch der Betreiber sind berechtigt, das Nutzungsverhältnis jederzeit ohne Angaben von Gründen und ohne Einhaltung einer Frist zu kündigen. Die Kündigung erfolgt durch Erklärung gegenüber dem anderen Teil in elektronischer Form (E-Mail) oder in Text- oder in Schriftform.
        br

    h2 Datenquellen:
    p
        a(href="https://www.data.gv.at/katalog/dataset/31416a3d-5a44-4f78-8b2c-765a2738aacc" target="_blank") Adressen Relationale Tabellen - Stichtagsdaten (BEV)
    p
        a(href="https://www.data.gv.at/katalog/dataset/da54b586-c25d-44b8-a786-c1d31e4b5cb2" target="_blank") KM-Tafeln Landesstraßen Land Salzburg (Land Salzburg)
    p
        a(href="https://www.data.gv.at/katalog/dataset/04836216-99f0-406c-b209-e1d892b4578f" target="_blank") Stationierung Fließgewässer Land Salzburg (Land Salzburg)
    p
        a(href="https://www.data.gv.at/katalog/dataset/2c54f4d6-5712-4e5a-a025-b7f7a396c39b" target="_blank") basemap.at Verwaltungsgrundkarte Österreich (geoland.at)
