const $ = window.$;
const {authAjax, getAppConfig} = require("../../main/lib.js");
const vars = getAppConfig();

const alertbox = require('../../modules/messagebox/alertbox');
const confirmbox = require('../../modules/messagebox/confirmbox');
const template = require('./textpresets.pug');
const templateRow = require('./textpresets-row.pug');
const templateEdit = require('./textpresets-edit.pug');
const textpresetType = require('./textpresetType');
const {load} = require("../../main/storage");
const typeList = [
    {
        id: textpresetType.ResourceNote,
        name: 'Bemerkung zum Einsatzmittel'
    },
    {
        id: textpresetType.AlertFeedback,
        name: 'Rückmeldung zum Einsatz'
    }
];

const start = function() {
    var tableBody = $('#textpresets-table > tbody');

    var editTextpreset = function(textpreset, isNew) {
        textpreset.id = typeof textpreset.id !== 'undefined' ? textpreset.id : -1;
        var editContent = $(templateEdit({textpreset, typeList}));
        editContent.find('textarea').keyup(function() {
            // do not allow newlines
            $(this).val($(this).val().replace(/\r?\n/gi, ''));
        });

        buildModal("Textvorlage " + (isNew ? 'hinzufügen' : 'bearbeiten'), editContent, function() {
            let $formContainer = $(this).closest('.modal-content').find('.textpresetsedit');
            saveChanges($formContainer); // eslint-disable-line no-use-before-define
        });
    };

    var refreshList = function() {
        $('body > .modal').modal('hide');
        $('#loadingScreen').show();

        authAjax("/textpresets/", function (data) {
            tableBody.empty();
            data.forEach(function(textpreset) {
                textpreset.typeName = typeList.find(x => x.id === textpreset.type).name;
                var row = $(templateRow({textpreset}));
                row.data('searchtext', textpreset.title);
                row.find('a[data-type="edit"]').click(() => {
                    editTextpreset(textpreset, false);
                });
                row.find('a[data-type="delete"]').click(() => {
                    deleteTextpreset(textpreset); // eslint-disable-line no-use-before-define
                });
                tableBody.append(row);
            });
            $('#loadingScreen').hide();
            $('#textpresets-search').keyup();
        });
    };

    var deleteTextpreset = function(textpreset) {
        confirmbox.open('Textvorlage löschen', 'Wollen Sie die Textvorlage endgültig löschen?', () =>{
            $('#loadingScreen').show();
            $.ajax({
                url: "/textpresets/",
                type: "DELETE",
                data: textpreset,
                headers: { authorization: load("token") },
                success: function (data) {
                    if (data === 'OK') {
                        refreshList();
                    } else {
                        alertbox.open('Fehler', data);
                    }
                },
                error: function(e) {
                    alertbox.open('Fehler', e.responseText || 'Es ist ein unbekannter Fehler aufgetreten.');
                }
            }).always(function() {
                $('#loadingScreen').hide();
            });
        });
    };

    var saveChanges = function(container) {
        let textpresetObject = {
            id: container.find("input[name='id']").val(),
            type: container.find("select[name='type']").val(),
            title: container.find("input[name='title']").val(),
            text: container.find("textarea[name='text']").val()
        };

        $('#loadingScreen').show();

        $.ajax({
            url: "/textpresets/",
            type: "POST",
            data: textpresetObject,
            headers: { authorization: load("token") },
            success: function (data) {
                if (data === 'OK') {
                    refreshList();
                } else {
                    alertbox.open('Fehler', data);
                }
            },
            error: function(e) {
                alertbox.open('Fehler', e.responseText || 'Es ist ein unbekannter Fehler aufgetreten.');
            }
        }).always(function() {
            $('#loadingScreen').hide();
        });
    };

    function buildModal($title, $content, $saveFunction) {
        let $modal = $('#modal-preset').clone();
        $modal.attr('id', '');
        $modal.find('.modal-title').html($title);
        $modal.find('.modal-body').html($content);

        if ($saveFunction !== null) {
            $modal.find('.modal-footer .btn-success').click($saveFunction);
        } else {
            $modal.find('.modal-footer').remove();
        }

        $modal.on('hidden.bs.modal', function () {
            $(this).remove();
        });

        $modal.modal('show');
    }

    $(document).ready(function() {
        $("#addNewLink").click(function() {
            editTextpreset({title: "", type: 1, text: ""}, true);
        });
        $("#loadingScreen").click(function() {
            $(this).hide();
        }).appendTo('body');
        $('#textpresets-search').keyup(function() {
            let $searchVal = $(this).val();
            let $allRows = $('#textpresets-table').find('tbody tr');
            if ($searchVal.length > 2) {
                $allRows.each(function() {
                    if ($(this).find('td:nth-child(1), td:nth-child(3)').text().search(new RegExp($searchVal, "i")) >= 0) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                $allRows.show();
            }
        });

        refreshList();
    });
};

module.exports = {
    template: () => template({vars}),
    start,
    typeList
};
