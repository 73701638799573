each user in users
    tr(data-id=user.id data-searchtext=createSearchtext(user))
        td
            b #{user.realname}
            br
            | #{PermissionsInv[user.permission]}
        td #{user.city}
        td #{getReadableDepartments(user)}
        td.text-center #{user.alertTimeout}
        td.text-center
            - if (user.alarmEmail)
                i.fa.fa-check-square(data-toggle="popover" data-content=user.alarmEmail data-original-title="" title="")
        td.text-center
            - if (user.wasserkarteToken)
                span(data-content=user.wasserkarteToken data-toggle="popover" data-original-title="" title="") #{getLastChars(user.wasserkarteToken)}
        td.text-center
            - if (user.objektdatenbankToken)
                span(data-content=user.objektdatenbankToken data-toggle="popover" data-original-title="" title="") #{getLastChars(user.objektdatenbankToken)}
        td.text-center.actions
            a.action-details.btn.btn-xs.btn-default(href="#navAdmin")
                i.fa.fa-eye
            a.action-login.btn.btn-xs.btn-success(href="#navAdmin")
                i.fa.fa-sign-in
            a.action-edit.btn.btn-xs.btn-warning(href="#navAdmin")
                i.fa.fa-pencil
            a.action-delete.btn.btn-xs.btn-danger(href="#navAdmin")
                i.fa.fa-trash
