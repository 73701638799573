script(src='/libs/jquery/jquery.autocomplete.js')
.page-search.page-2cols-2575
    div.left
        if mode === 'alarm'
            h1.margin-bottom-0 Einsatz anlegen
            h3.margin-top-0.margin-bottom-15 1. Einsatzort suchen
        else
            h1 Suche
        form.search(name='search', action='/search.html', method='POST', autocomplete='off')
            input#lat(type='hidden', value='')
            input#lng(type='hidden', value='')
            #search-modeswitch.margin-bottom-10
                input(name='searchType', type='hidden', value='streetNumber')
                div.form-group
                    .tabButtons
                        a.btn.btn-grey(data-searchtype='streetNumber', class='active') Adresse
                        |
                        a.btn.btn-grey(data-searchtype='kilometer') Kilometer
                        |
                        a.btn.btn-grey(data-searchtype='namensgut') Namensgut
                        |
                        a.btn.btn-grey(data-searchtype='hofname') Hofname
                        |
                        a.btn.btn-grey(data-searchtype='odb') Objektdatenbank
            .streetNumber
                div.form-group
                    label Bundesland
                    .input-group
                        select(name='state')
                div.form-group
                    label Gemeinde
                    .input-group
                        input.cityInput.form-control(type='text', required='required', autocomplete='das-ist-neu')
                        .input-group-addon.inputClear
                            i.fa.fa-times
                div.form-group
                    label Ortschaft oder Straße
                    .input-group
                        input.streetInput.form-control(type='text', required='required', autocomplete='off', autocorrect='off', autocapitalize='off', spellcheck='false')
                        .input-group-addon.inputClear
                            i.fa.fa-times
                div.form-group
                    label Hausnummer
                    .input-group
                        input.streetNumberInput.form-control(type='text', required='required', autocomplete='off', autocorrect='off', autocapitalize='off', spellcheck='false')
                        .input-group-addon.inputClear
                            i.fa.fa-times
            .kilometer(style='display:none;')
                div.form-group
                    label
                        span Straße, Strecke&nbsp;
                        span
                            span (
                            a#oebb-autofill ÖBB
                            span )&nbsp;
                        span oder Fluss
                    .input-group
                        input.autoStreetInput.form-control(type='text', required='required', disabled, autocomplete='off', autocorrect='off', autocapitalize='off', spellcheck='false')
                        .input-group-addon.inputClear
                            i.fa.fa-times
                div.form-group
                    label Kilometer
                    .input-group
                        input.streetKmInput.form-control(type='text', required='required', disabled, autocomplete='off', autocorrect='off', autocapitalize='off', spellcheck='false')
                        .input-group-addon.inputClear
                            i.fa.fa-times
            .namensgut(style='display:none;')
                div.form-group
                    label Name
                    .input-group
                        input.namensgutNameInput.form-control(type='text', required='required', disabled, autocomplete='off', autocorrect='off', autocapitalize='off', spellcheck='false')
                        .input-group-addon.inputClear
                            i.fa.fa-times
                div.form-group
                    label Ort
                    select.namensgutCitySelect.form-control(required='required', disabled)
            .hofname(style='display:none;')
                div.form-group
                    label Bundesland
                    .input-group
                        select(name='hofnameState')
                div.form-group
                    label Ort
                    .input-group
                        input.hofnameCityInput.form-control(type='text', required='required', disabled, autocomplete='off', autocorrect='off', autocapitalize='off', spellcheck='false')
                        .input-group-addon.inputClear
                            i.fa.fa-times
                div.form-group
                    label Hofname
                    .input-group
                        input.hofnameNameInput.form-control(type='text', required='required', disabled, autocomplete='off', autocorrect='off', autocapitalize='off', spellcheck='false')
                        .input-group-addon.inputClear
                            i.fa.fa-times
                input.hofnameStreet(type='hidden')
                input.hofnameStreetNumber(type='hidden')
            .odb(style='display:none;')
                input.odbMatchCategory(type='hidden', value='')
                div.form-group
                    label Hauptkategorie
                    .input-group
                        select(name='odbCategory')
                div.form-group.odbSubcategoryGroup(style='display:none;')
                    label Unterkategorie
                    .input-group
                        select(name='odbSubcategory')
                div.form-group
                    label Name
                    .input-group
                        input.odbNameInput.form-control(type='text', required='required', disabled, autocomplete='off', autocorrect='off', autocapitalize='off', spellcheck='false')
                        .input-group-addon.inputClear
                            i.fa.fa-times
            div.form-group
                if mode === 'alarm'
                    button.btn.btn-grey.active.search Einsatzort suchen
                else
                    button.btn.btn-grey.search Suchen

            if mode === 'alarm'
                hr
                div.form-group
                    a.btn.btn-grey(href='#navaddAlarm?noCoords=1') Einsatz ohne Koordinaten anlegen


        div.closeButton
            i.fa.fa-times
    div.right
        iframe#search-map.fullMap-iframe
    div.visible-sm.visible-xs.mobileNav
        div.showLeftCol
            i.fa.fa-search(aria-hidden="true")
            span Suche