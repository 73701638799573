const $ = window.$;
const {getUserConfig} = require('../../main/login');
const {createCrappyJson, isMobileView, authAjax, authAjaxGET, getAppConfig} = require('../../main/lib.js');
const {registerUpdateNotificationHandler} = require('../../main/socket.js');
const {getStatusColor} = require('../../leaflet/status');
const alertDetail = require("../../modules/alert-details/alert-details.js");
const notification = require("../../modules/notification/notification");
const tab = require('./tab.pug');
const dispolistTemplate = require('./dispolist.pug');

const template = require("./list.pug");

let activeIndex = 0;
let activeAlertId = 0;
let appConfig = getAppConfig();

let preserveScrollPosition = function() {
    const scrollTop = $('.left').scrollTop();
    const height = $('#listContainer').height();
    return {restore: function() {
        const newHeight = $('#listContainer').height();
        if (activeIndex > 0) {
            $('.left').scrollTop(scrollTop + newHeight - height);
        } else {
            $('.left').scrollTop(0);
        }
    }};
};

let updateMobileNavState = function() {
    if (activeIndex <= 0) {
        $('.mobileNav > .prev').addClass('inactive');
    } else {
        $('.mobileNav > .prev').removeClass('inactive');
    }
    if (activeIndex + 1 >= $('.listTab .tabLink').length) {
        $('.mobileNav > .next').addClass('inactive');
    } else {
        $('.mobileNav > .next').removeClass('inactive');
    }
};

let composeIframeUrl = function(href) {
    const userConfig = Object.assign({map: {ad: 0}}, getUserConfig());
    if (isMobileView()) {
        userConfig.map.ad = 1;
    } else {
        userConfig.map.ad = 0;
    }
    return href + "&c=" + createCrappyJson(userConfig);
};

let showMobileList = function() {
    $('.page-list').addClass("left--open");
};

let closeMobileList = function() {
    $('.page-list').removeClass("left--open");
};

let showAlertInMap = function(element, updateAlertId = 0) {
    if (element) {
        activeIndex = parseInt(element.dataset.index);
        activeAlertId = parseInt(element.dataset.alertId);
        $('.listTab').map((i, v) => v.classList.remove("active"));
        element.parentNode.classList.add("active");
        $('.showDispolist', element.parentNode).css('display', 'inline-block');
        updateMobileNavState();
        notification.resetNotificationWithTimeout(activeAlertId);
        if (updateAlertId === 0 || activeAlertId === updateAlertId) {
            const url = composeIframeUrl(element.getAttribute('data-href'));
            $('#detailMap').attr('src', '').attr('src', url);
        }
    }
};

const addStatusColors = function(resources) {
    return resources.map((resource) => {
        resource.color = getStatusColor(parseInt(resource.status)) || '#333333';
        if (resource.children) {
            resource.children = addStatusColors(resource.children);
        }
        return resource;
    });
};

const showDispoList = function(alertId) {
    const dispolist = $('#dispolist');
    dispolist.html('<i class="fa fa-spinner fa-pulse fa-2x"></i>');

    $('#dispolistContainer').addClass("opened");

    authAjaxGET(`/els/incidentResources?incidentId=${alertId}`)
        .done((data) => {
            data = addStatusColors(data);
            const renderedDispolist = dispolistTemplate({dispolistData: data});
            dispolist.html(renderedDispolist);
        })
        .fail((error) => {
            dispolist.html(`Fehler beim Laden der Daten: <b>${error.responseText}</b>`);
        });
};

const closeDispoList = function() {
    $('#dispolistContainer').removeClass("opened");
};

const tabClickHandler = function(tabEl) {
    closeMobileList();
    closeDispoList();
    $('#listContainer .showDispolist').hide();
    showAlertInMap(tabEl);
};

const initList = function(data, updateAlertId = 0) {
    const scrollPosition = preserveScrollPosition();
    var $listContainer = $("#listContainer");
    $listContainer.empty();
    if (data.length > 0) {
        data.forEach(function(action, index) {
            action.modifier = "list";
            const alertDetailTemplate = alertDetail({data: action});
            const tabTemplate = tab({alertDetail: alertDetailTemplate, alertId: action.id, alertStatus: action.status, index, appConfig});
            $listContainer.append(tabTemplate);
        });
        $('.listTab .tabLink').click(function () { tabClickHandler($(this).context); });
        $('.listTab')[0].classList.add("active");
        scrollPosition.restore();
        updateMobileNavState();
    } else {
        $listContainer.append("<h3>Derzeit keine Einsätze</h3>");
        $('#detailMap').attr('src', '');
        closeDispoList();
    }

    $listContainer.append('<div class="closeButton"><i class="fa fa-times"></i></div>');
    $('.closeButton', $listContainer).click(() => closeMobileList());

    $listContainer.find('.showDispolist').click(function() {
        showDispoList($(this).data('alert-id'));
    });

    // preselect first or active item:
    const preselectedItem = $('.listTab .tabLink[data-alert-id=' + activeAlertId + ']');
    if (preselectedItem.length > 0) {
        showAlertInMap(preselectedItem[0], updateAlertId);
    } else {
        showAlertInMap($('.listTab .tabLink').eq(0)[0], updateAlertId);
        closeDispoList();
    }
};

const loadList = function(updateAlertId = 0) {
    authAjax('/list', function(data) {
        initList(data, updateAlertId);
    });
};

const start = function() {
    loadList();

    $('.mobileNav > .next').click(() => {
        $('.listTab .tabLink').eq(activeIndex + 1).trigger('click');
    });
    $('.mobileNav > .prev').click(() => {
        $('.listTab .tabLink').eq(activeIndex - 1).trigger('click');
    });
    $('.mobileNav > .showList').click(() => showMobileList());
    $('#dispolistContainer .fa-times').click(closeDispoList);

    if (isMobileView()) {
        showMobileList();
    }
};

registerUpdateNotificationHandler((alertId) => {
    if ($('.page-list').length > 0) {
        loadList(true, alertId);
        if (activeAlertId === alertId && activeIndex !== 0) {
            notification.notifyShort();
        }
    }
});

module.exports = {
    start,
    template
};
