const { createElement: h, h2, div, a, object, span, i} = require('preact-hyperscript');
const { render, Component } = require('preact');

class OverviewDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timestamp: Date.now()
        };
    }
    componentDidMount() {
        this.interval = window.setInterval(() => {
            if (document.querySelector(`.container.overview${this.props.hours}`) === null) {
                if (this.interval) {
                    window.clearInterval(this.interval);
                }
            } else {
                this.setState({
                    timestamp: Date.now()
                });
            }
        }, 60 * 1000);
    }

    render({hours}, {timestamp, height, width}) {
        return div({className: `container overview${hours}`}, [
            h2({}, [
                a({href: "#navOverview"}, [i({className: 'fa fa-backward'})]),
                span({}, " "),
                hours > 24 ? `Alarmierungen in der letzten Woche` : `Alarmierungen in den letzten ${hours} Stunden`
            ]),
            object({type: 'image/svg+xml', height, width, data: `/overview?lastHours=${hours}&_=${timestamp}`, onLoad: (x) => this.setState({height: x.path[0].clientHeight, width: x.path[0].clientWidth})})
        ]);
    }
}

const start = function({container, hours}) {
    return render(
        h(OverviewDetail, {hours}),
        container.get(0)
    );
};

module.exports = {
    6: {
        template: () => "",
        start: ({container}) => start({container, hours: 6})
    },
    24: {
        template: () => "",
        start: ({container}) => start({container, hours: 24})
    },
    168: {
        template: () => "",
        start: ({container}) => start({container, hours: 168})
    }

};