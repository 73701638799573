.container
    h1 Benutzereinstellungen
    div.form.margin-top-20
        .row
            .col-sm-6.col-xs-12

                h2 Karte

                .form-group
                    label Standardkarte
                    select.form-control.form-control-auto(data-form="map.tl")
                        each preset in mapPresets
                            option(value=preset.key)=preset.name
                .form-group
                    label Zoom-Level
                    select.form-control.form-control-auto(data-form="map.z")
                        option 10
                        option 11
                        option 12
                        option 13
                        option 14
                        option 15
                        option 16
                        option(selected='selected') 17
                        option 18
                .form-group
                    label Adaptive Zoom
                    select.form-control.form-control-auto(data-form="map.az")
                        option(value='2') Standard (Aktiviert)
                        option(value='1') Aktiviert
                        option(value='0') Deaktiviert

                hr.margin-top-20
                h2 Daten-Layer

                .form-group
                    label Wasserkarte
                    div
                        input(data-form="map.dl.wts", data-toggle="toggle", data-size="small", data-on="Sichtbar", data-off="Versteckt", data-onstyle="success", data-offstyle="grey", value="1", type="checkbox")
                .form-group
                    label Fahrzeuge
                    div
                        input(data-form="map.dl.fzg", data-toggle="toggle", data-size="small", data-on="Sichtbar", data-off="Versteckt", data-onstyle="success", data-offstyle="grey", value="1", type="checkbox")
                #dataLayers
                    .form-group
                        label #caption#
                        div
                            input(data-form="map.dl.#shortCode#", data-toggle="toggle", data-size="small", data-on="Sichtbar", data-off="Versteckt", data-onstyle="success", data-offstyle="grey", value="1", type="checkbox")

            .col-sm-6.col-xs-12

                hr.margin-top-20.visible-xs-block
                h2 Sichtbarkeit

                .form-group
                    label Übersichtskarte
                    select.form-control.form-control-auto(data-form="map.om")
                        option(value='0') Standard
                        option(value='1') Sichtbar
                        option(value='2') Versteckt
                .form-group
                    label Button "GPS"
                    select.form-control.form-control-auto(data-form="map.gps")
                        option(value='0') Aus
                        option(value='1', selected='selected') Einschaltbar
                        option(value='2') Sofort aktiviert
                .form-group
                    label Button "Layer auswählen"
                    div
                        input(data-form="map.lm", data-toggle="toggle", data-size="small", data-on="Sichtbar", data-off="Versteckt", data-onstyle="success", data-offstyle="grey", value="1", type="checkbox")
                .form-group
                    label Button "PDF generieren"
                    div
                        input(data-form="map.pb", data-toggle="toggle", data-size="small", data-on="Sichtbar", data-off="Versteckt", data-onstyle="success", data-offstyle="grey", value="1", type="checkbox")
                .form-group
                    label Button "Externe Anwendungen"
                    div
                        input(data-form="map.ob", data-toggle="toggle", data-size="small", data-on="Sichtbar", data-off="Versteckt", data-onstyle="success", data-offstyle="grey", value="1", type="checkbox")
                .form-group
                    label Button "Zoom"
                    div
                        input(data-form="map.zb", data-toggle="toggle", data-size="small", data-on="Sichtbar", data-off="Versteckt", data-onstyle="success", data-offstyle="grey", value="1", type="checkbox")

                hr.margin-top-20
                h2 Mosaic

                .form-group
                    label Max Fenster
                    select.form-control.form-control-auto(data-form="mo.wd")
                        option(value='2') 2 Fenster
                        option(value='4') 4 Fenster
                        option(value='6', selected='selected') 6 Fenster
                        option(value='8') 8 Fenster
                .form-group
                    label Timeout
                    select.form-control.form-control-auto(data-form="mo.to")
                        option(value='1') 1 Minute
                        option(value='2') 2 Minuten
                        option(value='3') 3 Minuten
                        option(value='5') 5 Minuten
                        option(value='10', selected='selected') 10 Minuten
                        option(value='15') 15 Minuten
                        option(value='30') 30 Minuten
                        option(value='60') 60 Minuten
                        option(value='120') 2 Stunden
                        option(value='240') 4 Stunden
                        option(value='0') Keine Einschränkung

                hr.margin-top-20
                h2 Allgemein
                .form-group
                    label Timeout aktueller Einsatz
                    select.form-control.form-control-auto(data-form="at")
                        option(value='15') 15 Minuten
                        option(value='30') 30 Minuten
                        option(value='60') 1 Stunde
                        option(value='120') 2 Stunden
                        option(value='240', selected='selected') 4 Stunden
                        option(value='360') 6 Stunden
                        option(value='720') 12 Stunden
                        option(value='0') Keine Einschränkung
                .form-group
                    label Schnellstart Fenster
                    select.form-control.form-control-auto(data-form="qsw")
                        option(value='1') Aktiviert
                        option(value='0') Deaktiviert
                - if (enableGPSTracking === "1")
                    .form-group
                        label Fahrzeug Status
                        select.form-control.form-control-auto(data-form="map.sta")
                            option(value='0', selected='selected') Aus
                            option(value='1') Sofort Ein
                            option(value='15', selected='selected') Ein nach 15 Sekunden
                            option(value='30') Ein nach 30 Sekunden
                            option(value='60') Ein nach 1 Minute
                            option(value='120') Ein nach 2 Minuten
                            option(value='180') Ein nach 3 Minuten
                            option(value='300') Ein nach 5 Minuten
                .form-group
                    label Fahrzeuge verfolgen bei unbekanntem Einsatzort
                    input.form-control(data-form="map.fgi", data-type="array")
                .form-group
                    label Info Screen Website (HTTPS!)
                    input.form-control(data-form="iw", data-type="string")
                .form-group
                    label Startseite
                    select.form-control.form-control-auto(data-form="hp")
                        option(value='navIndex', selected='selected') Einsatzinfo
                        option(value='navList') Liste
                        option(value='navSearch') Suche
                        option(value='navMosaic') Mosaic
                        option(value='navSearchAlarm') Einsatz anlegen
                        option(value='navOverview') Übersichtskarte
                .form-group
                    label Bedienelemente Größe
                    select.form-control.form-control-auto(data-form="map.cz")
                        option(value='0.5') 0.5
                        option(value='0.6') 0.6
                        option(value='0.7') 0.7
                        option(value='0.8') 0.8
                        option(value='0.9') 0.9
                        option(value='1.0', selected='selected') Standard
                        option(value='1.1') 1.1
                        option(value='1.2') 1.2
                        option(value='1.3') 1.3
                        option(value='1.4') 1.4
                        option(value='1.5') 1.5
                        option(value='1.6') 1.6
                        option(value='1.7') 1.7
                        option(value='1.8') 1.8
                        option(value='1.9') 1.9
                        option(value='2.0') 2.0

    hr.margin-top-10
    div
        p
            | Achtung: Die Einstellungen gelten nur für das aktuelle Gerät (Laptop, Handy, ...)

    .margin-top-20
        button.btn.btn-success#applyConfig Speichern &amp; Anzeigen
        | &nbsp;
        button.btn.btn-grey#saveConfig Speichern
        | &nbsp;
        button.btn.btn-default#resetConfig Zurücksetzen

    .margin-top-20
        .panel.panel-default
            .panel-heading Aktuelle Konfiguration
            .panel-body
                div.breakWords=activeConfig

#loadingScreen(style='display: none;')
    i.fa.fa-cog.fa-spin.fa-3x.fa-fw
