div.container.scrollContainer
    h2.margin-bottom-10 Eingeloggte Benutzer
        a#reloadSessionData.btn.btn-success.btn-xs(style='margin-left: 15px;')
            i.fa.fa-refresh
    #listFilter
        .form-group
            .input-group
                span.input-group-addon
                    i.fa.fa-search
                input#sessions-search.form-control(type='text', placeholder='Suche...')
    #listContainer
        table#sessions-table.listing
            thead
                tr#headerRow
                    th Benutzername
                    th(style='width: 40%') Name
                    th.text-center(style='width: 125px') 24 Stunden
                        i.fa.fa-info-circle(style='margin-left: 5px;', title='Anzahl der Anmeldungen in den letzten 24 Stunden')
                    th.text-center(style='width: 160px') Aktuell verbunden
                        i.fa.fa-info-circle(style='margin-left: 5px;', title='Anzahl der aktuellen Socket-Verbindungen')
                    th(style='width: 30px')
            tbody
    #modal-preset.modal.fade(tabindex='-1', role='dialog')
        .modal-dialog(role='document')
            .modal-content
                .modal-header
                    button.close(type='button', data-dismiss='modal', aria-label='Close')
                        span(aria-hidden='true') ×
                    h4.modal-title Titel
                .modal-body Inhalt
    #loadingScreen(style='display: none;')
        i.fa.fa-cog.fa-spin.fa-3x.fa-fw
