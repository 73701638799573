const login = require('../../main/login.js');

const doPersistentLogin = function(token, sessionName) {

    login.doLoginToken(token, true, sessionName, function(status) {

        window.nativeAppRemote.persistentLoginCallback(status);

    });

};

const setUserConfig = function(userConfig) {

    login.setUserConfig(userConfig, true);

};

export {
    doPersistentLogin,
    setUserConfig
};