const nativeApp = require('../../modules/native-app/native-app.js');
const $ = window.$;
const template = require('./login.pug');

const {getLoginName, doLoginToken, isLoggedIn, doLogin, logout, resetSession} = require("../../main/login.js");

const start = function() {
    const sessionNameGroupEl = $("#sessionName").closest('.form-group');

    const refreshFormState = function() {
        if (isLoggedIn()) {
            $('.username').html(getLoginName());
            $("#loginForm").hide();
            $("#logoutForm").show();
        } else {
            $("#loginForm").show();
            $("#logoutForm").hide();
        }
    };

    sessionNameGroupEl.hide();
    $("#persistentLogin").change(function() {
        if ($(this).is(':checked')) {
            sessionNameGroupEl.show();
        } else {
            sessionNameGroupEl.hide();
        }
    });

    $('.loginToken').hide();
    $("[name=logintype]").change(function () {
        const value = $('input[name=logintype]:checked').val();
        if (value === "password") {
            $('.usernameAndPassword').show();
            $(".usernameAndPassword :input").attr("disabled", false);
            $('.loginToken').hide();
            $(".loginToken :input").attr("disabled", true);
        } else {
            $('.usernameAndPassword').hide();
            $(".usernameAndPassword :input").attr("disabled", true);
            $('.loginToken').show();
            $(".loginToken :input").attr("disabled", false);
        }
    });

    $(document).ready(function() {
        $("#loginForm").submit("submit", function() {
            resetSession(true);

            const logintype = $('input[name=logintype]:checked').val();
            if (logintype === "password") {
                doLogin($("#textBoxUsername").val(), $("#textBoxPassword").val(), $("#persistentLogin").is(':checked'), $("#sessionName").val());
            } else {
                if ($("#persistentLogin").is(':checked')) {
                    doLoginToken($("#textBoxLoginToken").val(), $("#persistentLogin").is(':checked'), $("#sessionName").val(), () => window.location = window.location.href.split("#").shift());
                } else {
                    window.location = "/l/" + $("#textBoxLoginToken").val() + window.location.search;
                }
            }
            return false;
        });
        $("#logoutForm").submit("submit", function() {
            logout();
            return false;
        });
        refreshFormState();
    });
};

module.exports = {
    template: () => template({
        isNativeApp: nativeApp.isActive()
    }),
    start
};