div.alert-details(class="alert-details--" + modifier)
    div.action_type.action_description=type
    div.action_description.action_description=description
    div.action_address.action_description=streetLAWZ + " " + streetNumber
    div.action_addressInfo.action_description=additionalAddressInfo
    div.action_place.action_description=placeLAWZ
    - if (alarmType === "alarm")
        div.action_time.action_description=timestamp
        div.action_alerted.action_description=vars.alertedDepartmentCaption + ": " + alerted
        div.action_level.action_description="Alarmstufe " + alertLevel
        - if (official)
            div.action_official.action_description=official
    - if (callerName || callerNumber)
        div.action_callerCaption.action_description="Anrufer:"
        div.action_callerWrapper
            div.action_callerName.action_description=callerName
            div.action_callerNumber.action_description
                - if (callerNumber)
                    a(href="tel:"+callerNumber)=callerNumber
        - if (callerName2 || callerNumber2)
            div.action_caller2Wrapper
                div.action_callerName2.action_description=callerName2
                div.action_callerNumber2.action_description
                    - if (callerNumber2)
                        a(href="tel:"+callerNumber2)=callerNumber2
