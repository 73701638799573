import {isLockedMode} from "../../main/login";

const $ = window.$;
const {getPermission, Permissions, isLoginAsMode, doLogoutAs, updateGUIUserData} = require('../../main/login.js');
const {createElementFromHTML, isDesktopView, getAppConfig} = require('../../main/lib');
const search = require('../../pages/search/search.js');
const list = require('../../pages/list/list.js');
const addAlarm = require('../../pages/add-alarm/add-alarm.js');
const config = require('../../pages/config/config.js');
const map = require('../../pages/map/map.js');
const admin = require('../../pages/admin/admin.js');
const departments = require('../../pages/departments/departments.js');
const textpresets = require('../../pages/textpresets/textpresets');
const sessions = require('../../pages/sessions/sessions');
const login = require('../../pages/login/login.js');
const about = require('../../pages/about/about.js');
const overview = require('../../pages/overview/overview.js');
const overviewDetail = require('../../pages/overview/overview-detail.js');
const helicopter = require('../../pages/helicopter/helicopter.js');
const mosaic = require('../../pages/mosaic/mosaic.js');
const nativeApp = require('../../modules/native-app/native-app.js');
const template = require('./nav.pug');
const {parseConfig} = require('../../leaflet/parse-config');

const renderTemplate = function() {
    const nav = document.querySelector('nav.navbar');
    const html = template({
        vars: getAppConfig(),
        isDesktop: isDesktopView()
    });
    nav.parentNode.replaceChild(createElementFromHTML(html), nav);
};

const setupGlobalChangePageApi = function(navigation) {
    window.changePage = function(name) {
        const destination = '#' + name;
        if (navigation[destination]) {
            window.location.hash = destination;
        }
    };
};

const initNavigation = function() {
    renderTemplate();
    updateGUIUserData();

    //Set which links are visible
    const navigation = {
        "#navIndex": map,
        "#navSearch": search.default,
        "#navAbout": about,
        "#navLinks": "#",
        "#navi122": "#",
        "#navTeam122": "#",
        "#navOdb": "#",
        "#navGeotraining": "#",
        "#navGeocode": "#",
        "#navLagekarte": "#"
    };

    if (!isLockedMode()) {
        navigation["#navPreferences"] = "#";
        navigation["#navConfig"] = config;
    }

    if (getPermission() >= Permissions.User) {
        navigation["#navUser"] = "#";
        navigation["#navAlert"] = "#";
        navigation["#navList"] = list;
        navigation["#navOverview"] = overview;
        navigation["#navOverviewDetail6h"] = overviewDetail[6];
        navigation["#navOverviewDetailDay"] = overviewDetail[24];
        navigation["#navOverviewDetailWeek"] = overviewDetail[168];
        navigation["#navHelicopter"] = helicopter;
        navigation["#navMosaic"] = mosaic;
    } else {
        navigation["#navLogin"] = login;
    }

    if (getPermission() >= Permissions.Command) {
        navigation["#navaddAlarm"] = addAlarm;
        navigation["#navSearchAlarm"] = search.alarm;
    }

    if (getPermission() >= Permissions.Admin) {
        navigation["#navAdmin"] = admin;
        navigation["#navAdminDepartments"] = departments;
        navigation["#navAdminTextpresets"] = textpresets;
        navigation["#navAdminSessions"] = sessions;
    }

    if (nativeApp.isActive()) {
        navigation["#navAppConfig"] = "#";
    }

    if (isLoginAsMode()) {
        navigation["#navLogoutAs"] = doLogoutAs;
    } else {
        if (!isLockedMode()) {
            navigation["#navLogout"] = login;
        } else {
            /* TODO
            navigation["#navUnlock"] = {
                template: () => {},
                start: () => {
                    setLockedMode(false);
                }
            };
            */
        }
    }

    const openPage = function(clickedLink, target, allLinks) {
        if (target === "#") {
            return;
        }
        if ($.isFunction(target)) {
            target();
        } else if (!target.template && target.indexOf("http") === 0) {
            window.location.href = target;
        } else {
            if (!target.template) {
                $("#content").load(target);
            } else {
                $("#content").html(target.template());
                target.start({container: $("#content")});
            }
            for (const inactiveLink in allLinks) {
                $(inactiveLink).parent().removeClass();
            }
            $(clickedLink).parent().addClass("active");
            const currentUl = $(clickedLink).closest('ul');
            if (currentUl.hasClass('dropdown-menu')) {
                currentUl.closest('li').addClass("active");
            }
            // close navbar on click (mobile device)
            if ($('.navbar-toggle').css('display') !== 'none') {
                $('#navbar').collapse('hide');
            }
        }
    };

    const pageClick = function(clickedLink, target, allLinks) {
        // Only load page when the same is already opened (reload)
        // Otherwise the hashchange function will do the page change
        if (clickedLink === window.location.hash) {
            openPage(clickedLink, target, allLinks);
        }
    };

    //Only show links defined as visible above.
    $("#navbar a").hide();

    for (const link in navigation) {
        $(link).show();
        //Also bind click handler to each link.
        $(link).click(pageClick.bind(null, link, navigation[link], navigation));
    }

    const navigateToHomepage = function() {
        const userConfig = parseConfig();

        const userHomepage = '#' + userConfig.hp;
        const defaultHomepage = Object.keys(navigation)[0];
        if (navigation[userHomepage]) {
            openPage(userHomepage, navigation[userHomepage], navigation);
        } else {
            openPage(defaultHomepage, navigation[defaultHomepage], navigation);
        }
    };

    const navigate = function() {
        //If we have an URL with a #, navigate to the correct link.
        const n = document.URL.indexOf("#");
        if (n !== -1) {
            const dest = document.URL.substring(n).split("?")[0];
            if ($.inArray(dest, navigation)) {
                openPage(dest, navigation[dest], navigation);
                return;
            }
        } else {
            navigateToHomepage();
        }
    };

    window.addEventListener("hashchange", navigate, false);

    navigate();

    $('.navbar .fa-refresh, .navbar .navbar-brand').on('click', function() {
        document.location = document.location.href.split("#")[0];
    });

    const $navbarHeader = $('.navbar-header');
    const $navbar = $('#navbar');
    const $navbarLoginname = $('#navUser > .loginname');
    $(window).resize(function () {
        const bodyWidth = $('body').width();
        const navWidth = $navbarHeader.outerWidth(true) + $navbar.outerWidth(true) - $navbarLoginname.outerWidth(true);
        const maxLoginnameWidth = bodyWidth - navWidth - 35;
        $navbarLoginname.css('maxWidth', maxLoginnameWidth);
    }).resize();

    setupGlobalChangePageApi(navigation);

};

module.exports = {
    initNavigation
};
