const template = require('./alert-details.pug');
const {getAppConfig} = require('../../main/lib');
const {parseConfig} = require('../../leaflet/parse-config');
const $ = window.$;

const mapObject = function(obj, func) {
    const tmp = {};
    Object.keys(obj).map(key => {
        tmp[key] = func(obj[key]);
    });
    return tmp;
};

const loadData = function(alertId, callback) {
    const userConfig = parseConfig();
    let url = "/lastAlert?timeout=" + parseInt(userConfig.at);
    if (alertId !== 'last') {
        url = "/alert?alertId=" + alertId;
    }
    let data;
    $.when(
        $.getJSON(url, {
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            _: new Date().getTime() // no cache
        }).done(function(tmp) {
            data = tmp;
        }).error(function() {
            // TODO: allow to show message if data is not loaded
            // showMessage('Einsatz konnte nicht geladen werden.');
        })
    ).then(function() {
        callback(data);
    });
};

const addZeroWidthSpace = function(value) {
    if (value && value.replace) {
        return value
            .replaceAll('[/]', '/\u200B')
            .replaceAll('[.]', '.\u200B');
    }
    return value;
};

const start = function({data, alertId}) {
    if (alertId) {
        let id = "lazy123";
        loadData(alertId, (dynamicData) => {
            dynamicData.vars = getAppConfig();
            $('#' + id).html(template(mapObject(data, addZeroWidthSpace)));
        });
        return '<div id="' + id + '"></div>';
    }

    return template(Object.assign(mapObject(data, addZeroWidthSpace), {vars: getAppConfig()}));
};

module.exports = start;
