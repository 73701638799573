const $ = window.$;
const {authAjax, authAjaxGET, getAppConfig} = require("../../main/lib.js");
const {PermissionsInv, Permissions, doLoginAs} = require("../../main/login.js");
const alertbox = require('../../modules/messagebox/alertbox');
const vars = getAppConfig();

const template = require('./admin.pug');
const editUserTemplate = require('./edit-user.pug');
const showUserTemplate = require('./show-user.pug');
const userList = require('./user-row.pug');
const {load} = require("../../main/storage");

const start = function() {
    let cityList = [];
    let departmentList = [];
    let externalResourcesList = [];
    let userListTableBody = $('#userlist-table > tbody');

    const getReadableDepartments = function(user) {
        return user.departments.reduce(function(all, cur) {
            return all + ", " + cur.name;
        }, "").substring(2);
    };

    const saveChanges = function(container) {

        let userObject = {
            id: container.find("input[name='id']").val(),
            realname: container.find("input[name='realname']").val(),
            permission: container.find("select[name='permission']").val(),
            username: container.find("input[name='username']").val(),
            email: container.find("input[name='email']").val(),
            alarmEmail: container.find("input[name='alarmEmail']").val(),
            departmentIds: container.find("select[name='departmentId']").val(),
            cityId: container.find("select[name='cityId']").val(),
            logincode: container.find("input[name='logincode']").val(),
            password: container.find("input[name='password']").val(),
            preventMultiLogin: container.find("input[name='preventMultiLogin']").is(':checked'),
            alertTimeout: container.find("input[name='alertTimeout']").val(),
            allowStatus: container.find("input[name='allowStatus']").is(':checked'),
            enableGPSTracking: container.find("input[name='enableGPSTracking']").is(':checked'),
            viewGPSLocations: container.find("input[name='viewGPSLocations']").is(':checked'),
            gpsColor: container.find("input[name='gpsColor']").val(),
            statusName: container.find("input[name='statusName']").val(),
            wasserkarteToken: container.find("input[name='wasserkarteToken']").val(),
            objektdatenbankToken: container.find("input[name='objektdatenbankToken']").val(),
            pdfConfig: container.find("input[name='pdfConfig']").val()
        };

        const externalResource = container.find("select[name='externalResource'] option:selected");
        if (externalResource.length) {
            userObject.externalId = externalResource.data('id');
            userObject.externalType = externalResource.data('type');
        }

        if (userObject.preventMultiLogin === true && userObject.logincode) {
            alertbox.open('Multi-Login', 'Das verhindern von mehreren gleichzeitigen Login`s ist mit einem Login-Code nicht möglich.');
            return;
        }

        $('#loadingScreen').show();

        $.ajax({
            url: "/users",
            type: "POST",
            data: userObject,
            headers: { authorization: load("token") },
            success: function (data) {
                if (data === 'OK') {
                    refreshList();
                } else {
                    alert(data); // eslint-disable-line no-alert
                }
            },
            error: function() {
                alert("Fehler."); // eslint-disable-line no-alert
            }
        }).always(function() {
            $('#loadingScreen').hide();
        });
    };

    const editUser = function(user, isNew) {
        const content = editUserTemplate({user, departmentList, cityList, Permissions, externalResourcesList, vars});
        buildModal("Benutzer " + (isNew ? 'hinzufügen' : 'bearbeiten'), content, function() {

            let $formContainer = $(this).closest('.modal-content').find('.useredit');

            saveChanges($formContainer);

        });

    };

    const loginAsUser = function(user) {
        if (user.permission !== Permissions.Admin) {
            doLoginAs(user.id);
        } else {
            // eslint-disable-next-line no-alert
            alert('Login als Admin nicht erlaubt');
        }
    };

    const showUser = function(user) {
        let externalName;

        if (user.externalId && user.externalType) {
            externalName = externalResourcesList.filter((r) => String(r.id) === user.externalId && String(r.type) === user.externalType).map((r) => r.name).shift();
            console.log(externalName);
            if (!externalName) {
                externalName = `${user.externalId} (${user.externalType})`;
            }
        }

        const readableDepartments = getReadableDepartments(user);
        const content = showUserTemplate({user, readableDepartments, PermissionsInv, externalName});

        buildModal("Benutzerdetails", content, null);

    };

    const deleteUser = function(user) {
        // eslint-disable-next-line no-alert
        if (confirm("User " + user.realname + " wirklich löschen?") === true) {
            $('#loadingScreen').show();
            $.ajax({
                url: "/users",
                type: "DELETE",
                data: { id: user.id },
                headers: { authorization: load("token") },
                success: function (data) {
                    if (data === 'OK') {
                        refreshList();
                    } else {
                        // eslint-disable-next-line no-alert
                        alert(data);
                    }
                },
                error: function() {
                    // eslint-disable-next-line no-alert
                    alert("Fehler.");
                }
            }).always(function() {
                $('#loadingScreen').hide();
            });
        }
    };

    const getRandomString = function (len) {
        let rand = "";
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < len; i++) {
            rand += charset.charAt(Math.floor(Math.random() * charset.length));
        }

        return rand;
    };

    const addUser = function() {
        editUser({realname: "",
            username: "",
            email: "",
            alarmEmail: "",
            permission: 0,
            logincode: "",
            departments: [],
            cityId: 0,
            password: getRandomString(10),
            preventMultiLogin: false,
            alertTimeout: 0,
            enableGPSTracking: false,
            viewGPSLocations: true,
            allowStatus: false,
            statusName: "",
            gpsColor: "",
            wasserkarteToken: "",
            objektdatenbankToken: "",
            pdfConfig: ""
        }, true);
    };

    function getLastChars(str) {
        return '...' + str.substr(str.length - 5);
    }

    function buildModal($title, $content, $saveFunction) {

        let $modal = $('#modal-preset').clone();

        $modal.attr('id', '');

        $modal.find('.modal-title').html($title);
        $modal.find('.modal-body').html($content);

        $modal.find("select.chosen").chosen();

        $modal.find('#togglePassword').click(togglePassword);

        if ($saveFunction !== null) {

            $modal.find('.modal-footer .btn-success').click($saveFunction);

        } else {

            $modal.find('.modal-footer').remove();

        }

        $modal.on('hidden.bs.modal', function () {
            $(this).remove();
        });

        $modal.modal('show');

    }

    function refreshList() {

        $('body > .modal').modal('hide');

        $('#loadingScreen').show();

        authAjax("/users", function (users) {
            userListTableBody.empty();
            const createSearchtext = (user) => [user.realname, user.city, user.username, user.email, user.alarmEmail, user.wasserkarteToken, user.objektdatenbankToken].join(' ');
            userListTableBody.append(userList({users, getLastChars, getReadableDepartments, PermissionsInv, createSearchtext}));
            users.forEach(user => {
                const userRow = userListTableBody.find(`tr[data-id=${user.id}]`);
                userRow.find('.action-details').click(showUser.bind(this, user));
                userRow.find('.action-login').click(loginAsUser.bind(this, user));
                userRow.find('.action-edit').click(editUser.bind(this, user));
                userRow.find('.action-delete').click(deleteUser.bind(this, user));
            });
            $('#loadingScreen').hide();
            $('[data-toggle="popover"]').popover({
                placement: 'top',
                trigger: 'hover'
            });
            $('#userlist-search').keyup();
        });
    }

    function togglePassword() {
        const passwordField = document.querySelector('[name=password]');
        console.log(passwordField);
        if (passwordField.getAttribute('type') === 'password') {
            passwordField.setAttribute('type', 'text');
        } else {
            passwordField.setAttribute('type', 'password');
        }
    }

    $(document).ready(function() {
        authAjax("/departments", function (departmentData) {
            departmentList = departmentData;
            authAjax("/cities", function (cityData) {
                cityList = cityData;
                refreshList();
            });
        });
        if (vars.els && vars.els.enabled === true) {
            let loadingLeitstelle = $('#loadingLeitstelle');
            loadingLeitstelle.show();
            authAjaxGET("/els/resources")
                .done((resources) => {
                    if (Array.isArray(resources)) {
                        externalResourcesList = resources;
                    }
                })
                .fail((error) => {
                    alertbox.open('Fehler', `Es konnten keine Daten aus der Leitstelle geladen werden: ${error.responseText}`);
                })
                .always(() => loadingLeitstelle.hide());
        }
        $("#addNewLink").click(addUser);
        $("#loadingScreen").click(function() {
            $(this).hide();
        }).appendTo('body');
        $('#userlist-search').keyup(function() {

            let $searchVal = $(this).val();

            let $allRows = $('#userlist-table').find('tbody tr');

            if ($searchVal.length > 2) {

                $allRows.each(function() {

                    if ($(this).data('searchtext').search(new RegExp($searchVal, "i")) >= 0) {

                        $(this).show();

                    } else {

                        $(this).hide();

                    }

                });

            } else {

                $allRows.show();

            }

        });
    });
};

module.exports = {
    template: () => template({vars}),
    start
};
