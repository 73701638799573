const $ = window.$;
const {authAjax} = require("../../main/lib.js");
const template = require('./sessions.pug');

var sessionsTableBody;

var detailSession = function(session) {
    const content = $('<div class="sessionElement"></div>');

    content.append('<div><b>Benutzer:</b> ' + session.email + '</div>');
    content.append('<div><b>Name:</b> ' + session.realname + '</div>');
    content.append('<div><b>Aktuell verbunden (Sockets):</b> ' + session.socketcount + '</div>');
    content.append('<div><b>Sessions (24h):</b> ' + session.sessioncount + '</div>');

    if (session.sessionnames) {
        content.append('<div><b>Sitzungsnamen (24h):</b><p>' + session.sessionnames.join('<br>') + '</p></div>');
    }

    const modal = $('#modal-preset').clone();
    modal.find('.modal-title').html('Session details');
    modal.find('.modal-body').html(content);
    modal.modal('show');
};

const createViewRow = function(row, session) {
    row.empty();
    row.append("<td><b>" + session.email + "</b></td>");
    row.append("<td>" + session.realname + "</td>");
    row.append("<td class='text-center'>" + session.sessioncount + "</td>");
    row.append("<td class='text-center'>" + session.socketcount + "</td>");

    const detailLink = $("<a href='#navAdminSessions' class='btn btn-xs btn-success'><i class='fa fa-eye'></i></a>");
    detailLink.click(detailSession.bind(this, session));
    row.append($("<td class='text-center'>").append(detailLink));

    row.data('searchtext', session.email + session.realname);
};

const refreshList = function() {
    $('#loadingScreen').show();
    sessionsTableBody.empty();
    authAjax("/activeSessions", function (data) {
        data.forEach(function(session) {
            var row = $("<tr>");
            createViewRow(row, session);
            sessionsTableBody.append(row);
        });
        $('#loadingScreen').fadeOut(150);
    });
};

const initSearch = function() {
    $('#sessions-search').keyup(function() {
        let $searchVal = $(this).val();
        let $allRows = $('#sessions-table').find('tbody tr');
        if ($searchVal.length > 2) {
            $allRows.each(function() {
                if ($(this).data('searchtext').search(new RegExp($searchVal, "i")) >= 0) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        } else {
            $allRows.show();
        }
    });
};

const start = function() {
    sessionsTableBody = $('#sessions-table > tbody');
    $('#reloadSessionData').click(refreshList);
    initSearch();
    refreshList();
};

module.exports = {
    template: () => template(),
    start
};