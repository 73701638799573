.page-list.page-2cols-2575.fullscreen-area
    div.left
        #listContainer
    div.right
        iframe#detailMap.fullMap-iframe(src="" name="detailMap")
    div.visible-sm.visible-xs.mobileNav
        div.prev
            i.fa.fa-arrow-circle-left(aria-hidden="true")
        div.showList
            i.fa.fa-folder-open(aria-hidden="true")
            span Einsatzliste
        div.next
            i.fa.fa-arrow-circle-right(aria-hidden="true")
    #dispolistContainer
        h3 Alarmierte Einheiten
        #dispolist
        i.fa.fa-times