.page-login.container
    div
        form#loginForm.form(action='#' name="loginform")
            .form-group.logintype
                span Logintyp:
                label
                    input#logintypePassword(name='logintype' type='radio' value="password" checked="checked")
                    span Benutzer/Passwort
                label
                    input#logintypeToken(name='logintype' type='radio' value="token")
                    span Login-Code
            .usernameAndPassword
                .form-group
                    label(for='textBoxUsername') Benutzername
                    input#textBoxUsername.form-control(type='text', placeholder='Benutzername')
                .form-group
                    label(for='textBoxPassword') Passwort
                    input#textBoxPassword.form-control(type='password', placeholder='Passwort')
            .loginToken
                .form-group
                    label(for='textBoxLoginToken') Login-Code
                    input#textBoxLoginToken.form-control(type='password', placeholder='loginToken')
            .form-group
                input#persistentLogin(type='checkbox')
                label(for='persistentLogin') Login dauerhaft speichern?
            .form-group
                label(for='sessionName') Sitzungsname (optional)
                input#sessionName.form-control(type='text', placeholder='z.B. Handy KDO')
            .form-group
                input#buttonLogin.btn.btn-default(type='submit', value='Login')
        form#logoutForm.form-inline(action='#')
            input#buttonLogout.btn.btn-default(type='submit', value='Logout')
            i.fa.fa-user
            span.username
        p
            br
        p
        h3 Logindaten
        | 		Die Logindaten können vom OFK oder Schriftführer jeder Feuerwehr in Salzburg beantragt werden.
        br
        | 		Email an:&nbsp;
        a(href='mailto:info@122.co.at') info@122.co.at
        br
        | 		Inhalt: Name der Feuerwehr, Name des OFK, Telefonnr.
        br
        | 		Email bitte von der offiziellen LFV Adresse senden.
        br
        | 		Für die Benützung des Systems entstehen keine Kosten.
        p
        p
        h3 Support Team
        | 		Euer&nbsp;
        a(href='https://feuerwehreinsatz.info') feuerwehreinsatz.info
        |  Support Team
        br
        | 		Andreas Rauchenbacher (FF St. Johann/Pg.)
        br
        | 		Lukas Fritzenwanger (FF Uttendorf)
        br
        | 		Stefan Hofer (FF Saalfelden)
        br
        | 		Stefan Vötter (FF Kuchl)
        p
        - if (isNativeApp === false)
            h3 Download als App
            h4 Vorteile gegenüber Webversion:
            p
                ul
                    li Größere Bildschirmfläche (da es keine Browserzeile gibt)
                    li Benutzerkonfiguration wird automatisch gespeichert
            p
                | Nach der Installation werdet ihr nach dem Login gefragt:
                br
                br
            h4 Vorschlag zur Vorgehensweise für besseren Datenschutz:
            p
                | Es gibt jemanden in der Feuerwehr der den Code kennt und jene Mitglieder die die App haben wollen kommen zu diesem Mitglied und lassen sich den Logincode auf ihrem Handy eingeben.
                | Damit wird die Weitergabe des Login unterbunden.
                | Im Prinzip läuft das also ähnlich wie mit der Funkrufempfänger Ausgabe bzw. die Programmierung der RICs
                br
            h4 Apps können hier für Google und Apple Endgeräte heruntergeladen werden:
            p
                .row
                    .col-md-2.col-xs-4
                        a(target='_blank', href='https://geo.itunes.apple.com/at/app/feuerwehreinsatz.info/id1083837106?mt=8')
                            img(alt='Get it on Apple App Store', style='width: 100%; height: auto;', src='https://linkmaker.itunes.apple.com/images/badges/de-de/badge_appstore-lrg.svg')
                    .col-md-2.col-xs-4
                        a(target='_blank', href='https://play.google.com/store/apps/details?id=at.fwei.app&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1')
                            img(alt='Get it on Google Play', style='width: 100%; height: auto;', src='https://play.google.com/intl/en_us/badges/images/generic/de-play-badge.png')
                    .col-md-8.col-xs-4
