const $ = window.$;
const {registerUpdateHandler} = require('../../main/socket');

const basePath = '/sounds/';
const audioFiles = {
    alert: 'alert.mp3'
};
const notifyTimeoutMs = {
    short: 20 * 1000,
    long: 30 * 60 * 1000
};

let currentAlert;
let longNotifyTimeout;
let shortNotifyTimeout;
let stopAfterShortNotifcation = false;

const playAudioOnce = function(audiofile) {
    const notificationSound = new Audio(basePath + audiofile);
    notificationSound.play().catch(() => {
        console.log("Fehler beim Abspielen des Benachrichtigungstons");
    });
};

const updateStatusBar = function(show = true) {
    if (show) {
        $('body').addClass('alertNotification');
    } else {
        $('body').removeClass('alertNotification');
    }
};

const clearTimeouts = function() {
    if (longNotifyTimeout) {
        clearTimeout(longNotifyTimeout);
    }
    if (shortNotifyTimeout) {
        clearTimeout(shortNotifyTimeout);
    }
    longNotifyTimeout = null;
    shortNotifyTimeout = null;
};

const stopNotification = function() {
    clearTimeouts();
    updateStatusBar(false);
};

const notifyNewOrUpdateAlert = function() {
    playAudioOnce(audioFiles.alert);
    updateStatusBar();
    clearTimeouts();

    stopAfterShortNotifcation = false;

    shortNotifyTimeout = setTimeout(() => {
        shortNotifyTimeout = null;
        if (stopAfterShortNotifcation === true) {
            stopNotification();
        } else {
            longNotifyTimeout = setTimeout(stopNotification, notifyTimeoutMs.long);
        }
    }, notifyTimeoutMs.short);
};

const notifyShort = function() {
    if (!longNotifyTimeout) {
        notifyNewOrUpdateAlert();
        stopAfterShortNotifcation = true;
    } else {
        playAudioOnce(audioFiles.alert);
    }
};

const resetNotification = function(alertId) {
    if (!alertId || (currentAlert && currentAlert.id === alertId)) {
        if (shortNotifyTimeout) {
            stopAfterShortNotifcation = true;
        } else {
            stopNotification();
        }
    }
};

const resetNotificationWithTimeout = function(alertId, timeoutMs = 500) {
    setTimeout(() => {
        resetNotification(alertId);
    }, timeoutMs);
};

const init = function() {
    registerUpdateHandler((alert) => {
        currentAlert = alert;
        if (currentAlert && currentAlert.alarmType === 'alarm') {
            notifyNewOrUpdateAlert();
        }
    });
};

module.exports = {
    resetNotification,
    resetNotificationWithTimeout,
    notifyShort,
    init
};