const {load} = require("./storage");
const $ = window.$;

const getAppConfig = function() {
    return JSON.parse(document.querySelector('#appVars').text);
};

function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}

const getConfig = function() {
    return window.config;
};

const isInIframe = window.frameElement && window.frameElement.nodeName === "IFRAME";

// Helper function for fetching GET parameters
const getParameterByName = function(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const getHashParameterByName = function(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.hash);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const getResponsiveStatus = function() {
    return parseInt($('#respIndicator').css('zIndex'));
};

const isMobileView = function() {
    return getResponsiveStatus() <= 5;
};

const isTabletView = function() {
    return getResponsiveStatus() === 10;
};

const isDesktopView = function() {
    return getResponsiveStatus() >= 15;
};

const isIOS = function() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

// https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
const isTouchDevice = function() {

    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    const mq = function (query) {
        return window.matchMedia(query).matches;
    };

    // eslint-disable-next-line no-undef
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
};

const scrollDownOnFocus = function(scrollContainer) {
    if (isMobileView()) {
        $(window).resize(function() {
            scrollContainer.find("input:focus").each(function() {
                $(this).focus();
            });
        });
        scrollContainer.find('input').on('focus', function() {
            scrollContainer.scrollTop(scrollContainer.scrollTop() + $(this).offset().top - scrollContainer.offset().top - 30);
        });
    }
};

const removeURLParameter = function(url, parameter) {
    //prefer to use l.search if you have a location/link object
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {

        const prefix = encodeURIComponent(parameter) + '=';
        const pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (let i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url = urlparts[0] + '?' + pars.join('&');
        return url;
    } else {
        return url;
    }
};

const parseCrappyJson = function(crappyJson) {
    try {
        const fixedJson = crappyJson.replace(/(['"])?([a-zA-Z0-9ÖÄÜöäüß_\-\.%\/]+)(['"])?/g, '"$2"');
        return JSON.parse(fixedJson);
    } catch (e) {
        console.log("crapyJson could not be parsed");
        console.log(crappyJson);
        return {};
    }
};

const createCrappyJson = function(json) {
    return JSON.stringify(json).replaceAll('"', '');
};

const authAjax = function(url, callback) {
    $.ajax({
        type: "GET",
        url: url,
        dataType: 'json',
        async: false,
        data: '{}',
        beforeSend: function (xhr) {
            xhr.setRequestHeader('authorization', load("token"));
        },
        success: callback
    });
};

const authAjaxGET = function(url) {
    return $.ajax({
        type: "GET",
        url: url,
        dataType: 'json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader('authorization', load("token"));
        }
    });
};

const createDropDown = function(name, values, value) {
    let dropdown;
    const multi = value instanceof Array;

    if (multi) {
        dropdown = $("<select multiple />");
    } else {
        dropdown = $("<select />");
    }
    for (let i = 0; i < values.length; i++) {
        const option = $("<option />", { value: values[i].id || values[i].value, html: values[i].name || values[i].data });
        dropdown.append(option);
    }
    dropdown.attr("name", name);
    dropdown.val(value);

    return dropdown;
};

const round = function(x, p) {
    return Math.round(x * Math.pow(10, p)) / Math.pow(10, p);
};

const createElementFromHTML = function(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    return div.firstChild;
};

if (!Array.prototype.filter) {
    Array.prototype.filter = function(fun/*, thisArg*/) { // eslint-disable-line no-extend-native
        'use strict';

        if (this === void 0 || this === null) {
            throw new TypeError();
        }

        var t = Object(this);
        var len = t.length >>> 0;
        if (typeof fun !== 'function') {
            throw new TypeError();
        }

        var res = [];
        var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
        for (var i = 0; i < len; i++) {
            if (i in t) {
                var val = t[i];

                // NOTE: Technically this should Object.defineProperty at
                //       the next index, as push can be affected by
                //       properties on Object.prototype and Array.prototype.
                //       But that method's new, and collisions should be
                //       rare, so use the more-compatible alternative.
                if (fun.call(thisArg, val, i, t)) {
                    res.push(val);
                }
            }
        }

        return res;
    };
}

const addFullScreenShortcut = function() {
    const F = 70;
    document.onkeydown = function(e) {
        if (e.ctrlKey && e.which === F) {
            const elem = document.querySelector('.fullscreen-area') || document.querySelector('body');
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
        }
    };
};

const addLockModeShortcut = function(setLockedMode) {
    const L = 76;
    document.onkeydown = function(e) {
        if (e.ctrlKey && e.which === L) {
            setLockedMode();
        }
    };
};

const copyToClipboard = (function(window, document, navigator) {
    let textArea;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        let range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function execCopy() {
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    return function(text) {
        createTextArea(text);
        selectText();
        execCopy();
    };

}(window, document, navigator));

const getParents = function (elem) {
    var parents = [];
    for (; elem && elem !== document; elem = elem.parentNode) {
        parents.push(elem);
    }
    return parents;
};

module.exports = {
    getAppConfig,
    isInt,
    getParameterByName,
    getHashParameterByName,
    isIOS,
    getResponsiveStatus,
    isMobileView,
    isTabletView,
    isDesktopView,
    isTouchDevice,
    scrollDownOnFocus,
    removeURLParameter,
    authAjax,
    authAjaxGET,
    createDropDown,
    round,
    parseCrappyJson,
    createCrappyJson,
    copyToClipboard,
    isInIframe,
    createElementFromHTML,
    addFullScreenShortcut,
    addLockModeShortcut,
    getConfig,
    getParents
};
