const $ = window.$;
const {getAlertTimeout} = require('../../main/login');
const {registerUpdateHandler} = require('../../main/socket.js');
const notification = require("../../modules/notification/notification");
const lib = require('../../main/lib.js');

const template = require('./map.pug');

let mapReloadInterval;

const restartMapReloadTimer = function() {
    if (typeof mapReloadInterval !== "undefined") {
        window.clearInterval(mapReloadInterval);
    }
    const userAlertTimeout = getAlertTimeout();
    const alertRefreshTimeout = Math.min(userAlertTimeout, 60);
    mapReloadInterval = window.setInterval(function () {
        $('#action_map').attr('src', $('#action_map').attr('src'));
    }, alertRefreshTimeout * 60 * 1000);
};

const updateMap = function({selector, c} = {}) {
    if (!selector) {
        selector = "#action_map";
    }
    restartMapReloadTimer();
    var query = "/leaflet/?alertId=last&";
    if (c === undefined) {
        c = lib.getParameterByName("c");
    }
    if (c !== "") {
        query += "c=" + c + "&";
    }

    let map = $(selector);
    if (map.length > 0) {
        map.attr("src", query);
        notification.resetNotificationWithTimeout();
    }
};

const start = function() {
    updateMap();
    registerUpdateHandler(updateMap);
    window.setTimeout(function() {
        $('#action_map')[0].contentWindow.focus();
    }, 1000);
};

module.exports = {
    template,
    start,
    updateMap
};