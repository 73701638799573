const $ = window.$;
const {authAjax, createDropDown, getAppConfig} = require("../../main/lib.js");
const vars = getAppConfig();

const template = require('./departments.pug');
const {load} = require("../../main/storage");

const start = function() {
    var cityList = [];
    var departmentListTableBody = $('#departments-table > tbody');

    var createViewRow = function(row, department) {

        row.empty();
        row.append("<td><b>" + department.name + "</b></td>");
        row.append("<td>" + department.city + "</td>");
        row.append("<td>" + department.organizationId + "</td>");

        let editLink = $("<a href='#navAdminDepartments' class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></a>");
        // eslint-disable-next-line no-use-before-define
        editLink.click(editDepartment.bind(this, department, row, false));
        row.append($("<td class='text-center'>").append(editLink));

        row.data('searchtext', department.name);

    };

    var refreshList = function() {

        $('body > .modal').modal('hide');

        $('#loadingScreen').show();

        authAjax("/departments", function (data) {
            departmentListTableBody.empty();
            data.forEach(function(department) {
                var row = $("<tr>");
                createViewRow(row, department);
                departmentListTableBody.append(row);
            });
            $('#loadingScreen').hide();
            $('[data-toggle="popover"]').popover({
                placement: 'top',
                trigger: 'hover'
            });
            $('#departmentlist-search').keyup();
        });
    };

    var saveChanges = function(container) {

        let departmentObject = {
            id: container.find("input[name='id']").val(),
            name: container.find("input[name='name']").val(),
            cityId: container.find("select[name='cityId']").val(),
            organizationId: container.find("input[name='organizationId']").val()
        };

        $('#loadingScreen').show();

        $.ajax({
            url: "/departments",
            type: "POST",
            data: departmentObject,
            headers: { authorization: load("token") },
            success: function (data) {
                if (data === 'OK') {
                    refreshList();
                } else {
                    alert(data); // eslint-disable-line no-alert
                }
            },
            error: function() {
                alert("Fehler."); // eslint-disable-line no-alert
            }
        }).always(function() {
            $('#loadingScreen').hide();
        });
    };

    var editDepartment = function(department, row, isNew) {

        let $content = $('<div class="departmentedit"></div>');

        $content.append('<input type="hidden" name="id" value="' + (typeof department.id !== 'undefined' ? department.id : '-1') + '" />');
        $content.append('<div><b>Name:</b><br/><input type="text" name="name" value="' + department.name + '" /></div>');
        $content.append('<div><b>Organisations-ID:</b><br/><input type="text" name="organizationId" value="' + department.organizationId + '" /></div>');

        $content.append($('<div><b>Ort:</b><br/></div>').append(createDropDown("cityId", cityList, department.cityId, false)));
        $content.find("select[name='cityId']").chosen();

        buildModal("Department " + (isNew ? 'hinzufügen' : 'bearbeiten'), $content, function() {

            let $formContainer = $(this).closest('.modal-content').find('.departmentedit');

            saveChanges($formContainer);

        });

    };

    var addDepartment = function(e) {
        var row = $("<tr>");
        $("#headerRow").after(row);
        editDepartment({name: "", organizationId: 0}, row, true, e);
    };

    function buildModal($title, $content, $saveFunction) {

        let $modal = $('#modal-preset').clone();

        $modal.attr('id', '');

        $modal.find('.modal-title').html($title);
        $modal.find('.modal-body').html($content);

        if ($saveFunction !== null) {

            $modal.find('.modal-footer .btn-success').click($saveFunction);

        } else {

            $modal.find('.modal-footer').remove();

        }

        $modal.on('hidden.bs.modal', function () {
            $(this).remove();
        });

        $modal.modal('show');

    }

    $(document).ready(function() {
        authAjax("/cities", function (cityData) {
            cityList = cityData;
            refreshList();
        });
        $("#addNewLink").click(addDepartment);
        $("#loadingScreen").click(function() {
            $(this).hide();
        }).appendTo('body');
        $('#departmentlist-search').keyup(function() {

            let $searchVal = $(this).val();

            let $allRows = $('#departments-table').find('tbody tr');

            if ($searchVal.length > 2) {

                $allRows.each(function() {

                    if ($(this).data('searchtext').search(new RegExp($searchVal, "i")) >= 0) {

                        $(this).show();

                    } else {

                        $(this).hide();

                    }

                });

            } else {

                $allRows.show();

            }

        });
    });
};

module.exports = {
    template: () => template({vars}),
    start
};
