const $ = window.$;

const initServiceWorker = function() {
    if ('serviceWorker' in navigator) {
        if (!navigator.serviceWorker.controller) {
            navigator.serviceWorker.register('/service-worker.js', {
                scope: '/'
            }).then(function() {
                console.log('Service worker registered');
            });
        }
    } else if ('applicationCache' in window) {
        $(function() {
            console.log("AppCache registered");
            $('body').append('<iframe style="display: none;" src="/appcache.html"></iframe>');
        });
    }
};

const removeAllServiceWorker = function() {
    console.log('remove all service worker');
    navigator.serviceWorker.getRegistrations().then(
        function (registrations) {
            for (let registration of registrations) {
                registration.unregister()
                    .then(function () {
                        return self.clients.matchAll();
                    })
                    .then(function (clients) {
                        clients.forEach(client => {
                            console.log('service worker removed');
                            if (client.url && "navigate" in client) {
                                client.navigate(client.url);
                            }
                        });
                    });
            }
        }
    );
};

export {
    initServiceWorker,
    removeAllServiceWorker
};