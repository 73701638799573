const $ = window.$;
const template = require('./messagebox.pug');

module.exports = function(title, content, removeOnClose = true) {
    let messagebox;
    let loadingIcon;

    const open = function() {
        messagebox.fadeIn(300);
    };

    const close = function() {
        messagebox.fadeOut(300, () => {
            if (!removeOnClose) {
                return;
            }

            messagebox.remove();
            messagebox = null;
            loadingIcon = null;
        });
    };

    const loading = function(isLoading) {
        if (loadingIcon) {
            if (isLoading) {
                loadingIcon.show();
            } else {
                loadingIcon.hide();
            }
        }
    };

    messagebox = $(template({title, content}));
    messagebox.hide();
    messagebox.find('.messagebox-close, .btn[data-type="close"]').click(function() {
        close($(this).closest('.messagebox-background'));
    });

    loadingIcon = messagebox.find('.messagebox-loading');

    $('body').append(messagebox);

    return {
        open,
        close,
        loading,
        messagebox
    };
};