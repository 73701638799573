nav.navbar.navbar-inverse.navbar-fixed-top
    .navbar-header
        button.navbar-toggle.collapsed(type='button', data-toggle='collapse', data-target='#navbar', aria-expanded='false', aria-controls='navbar')
            span.sr-only Navigation
            span.icon-bar
            span.icon-bar
            span.icon-bar
        button.refresh.hidden-sm.hidden-md.hidden-lg.visible-xs-block(type='button')
            span.sr-only Reload
            i.fa.fa-spin-hover.fa-refresh
        img.navbar-brand(src='/images/' + vars.logofile, style='margin: 0px; padding: 0px')
    #navbar.collapse.navbar-collapse
        ul.nav.navbar-nav
            li
                a#navIndex(href='#navIndex')
                    i.fa.fa-map-marker
                    span.navtext Einsatzinfo
            li
                a#navSearch(href='#navSearch')
                    i.fa.fa-search
                    span.navtext Suche
            li.dropdown
                a#navAlert.dropdown-toggle(href='#', data-toggle='dropdown', role='button', aria-haspopup='true', aria-expanded='false')
                    i.fa.fa-bolt
                    span.navtext Einsätze
                    span.caret
                ul.dropdown-menu
                    li
                        a#navList(href='#navList')
                            i.fa.fa-list
                            span.navtext Liste
                    li
                        a.hidden-xs.hidden-sm.hidden-md#navMosaic(href='#navMosaic')
                            i.fa.fa-columns
                            span.navtext Mosaic
                    li
                        a#navaddAlarm(href='#navSearchAlarm')
                            i.fa.fa-plus
                            span.navtext Einsatz hinzufügen
                    li
                        a#navOverview(href='#navOverview')
                            i.fa.fa-map
                            span.navtext Übersichtskarte
            li.dropdown
                a#navLinks.dropdown-toggle(href='#', data-toggle='dropdown', role='button', aria-haspopup='true', aria-expanded='false')
                    i.fa.fa-external-link
                    span.navtext Links
                    span.caret
                ul.dropdown-menu
                    li
                        a#navTeam122(href='https://www.team122.at/', target='_blank')
                            i.fa.fa-fw.fa-users
                            span.navtext Team122
                    li
                        a#navOdb(href='https://www.objektdatenbank.at/', target='_blank')
                            i.fa.fa-fw.fa-map-marker
                            span.navtext Objektdatenbank
                    li
                        a#navGeotraining(href='https://www.geo-training.at/', target='_blank')
                            i.fa.fa-fw.fa-globe
                            span.navtext GeoTraining
                    li
                        a#navGeocode(href='https://www.geocode.at/', target='_blank')
                            i.fa.fa-fw.fa-crosshairs
                            span.navtext Geocode
                    li
                        a#navLagekarte(href='https://www.lagekarte.info/', target='_blank')
                            i.fa.fa-fw.fa-map
                            span.navtext Lagekarte
            li
                a#navAbout(href='#navAbout')
                    i.fa.fa-info
                    span.navtext Über Uns
            li#navPreferencesContainer.dropdown
                a#navPreferences.dropdown-toggle(href='#', data-toggle='dropdown', role='button', aria-haspopup='true', aria-expanded='false')
                    i.fa.fa-cog
                    span.navtext Einstellungen
                    span.caret
                ul.dropdown-menu
                    li
                        a#navConfig(href='#navConfig')
                            i.fa.fa-map
                            span.navtext Karteneinstellungen
                    li
                        a#navAppConfig
                            i.fa.fa-cog
                            span.navtext App-Einstellungen
            li
                a#navLogin(href='#navLogin')
                    i.fa.fa-user
                    span.actionname.navtext Login
            li#navUserContainer.dropdown
                a#navUser.dropdown-toggle(href='#', data-toggle='dropdown', role='button', aria-haspopup='true', aria-expanded='false')
                    i.fa.fa-user
                    span.loginname.navtext
                ul.dropdown-menu
                    li.nav-username
                        span.loginname
                    li
                        a#navAdmin(href='#navAdmin')
                            i.fa.fa-cogs
                            span.navtext Admin
                    li
                        a#navAdminSessions(href='#navAdminSessions')
                            i.fa.fa-address-card-o
                            span.navtext Eingeloggte Benutzer
                    li
                        a#navAdminDepartments(href='#navAdminDepartments')
                            i.fa.fa-building
                            span.navtext Departments
                    li
                        a#navAdminTextpresets(href='#navAdminTextpresets')
                            i.fa.fa-file-text-o
                            span.navtext Textvorlagen
                    li
                        a#navLogoutAs(href='#navLogoutAs')
                            i.fa.fa-sign-out
                            span.navtext Logout As
                    li
                        a#navLogout(href='#navLogout')
                            i.fa.fa-sign-out
                            span.actionname.navtext Logout
                    li
                        a#navUnlock(href='#navUnlock')
                            i.fa.fa-sign-out
                            span.actionname.navtext Entsperren
