const login = require('./login.js');
const {load} = require("./storage");

const $ = window.$;
const io = window.io;

// public function
let lastAction;

const updateHandler = [];
const updateNotificationHandler = [];

const registerUpdateHandler = function(handler) {
    updateHandler.push(handler);
};

const registerUpdateNotificationHandler = function(handler) {
    updateNotificationHandler.push(handler);
};

const cleanupForCompare = function(alert) {
    const cleanedAlert = JSON.parse(JSON.stringify(alert)); // copy object to not change the orig alert
    // do not compare status for now. only shown in alert list and therefore
    // it uses the registerUpdateNotificationHandler to respect every change
    delete cleanedAlert.status;
    return cleanedAlert;
};

const compareAlert = function(alert1, alert2) {
    return JSON.stringify(cleanupForCompare(alert1)) !== JSON.stringify(cleanupForCompare(alert2));
};

const initSocketIO = function() {
    console.log("start socket connection");
    var socket = io();

    // attention: reloaded on every map reload -> many events
    socket.on('action', function(action) {
        if (lastAction && compareAlert(action, lastAction)) {
            for (let i in updateHandler) {
                updateHandler[i](action);
            }
            login.checkLogin();
        }
        lastAction = action;
    });

    var requestUpdate = function() {
        const token = load("token");
        socket.emit("refresh", {
            token: token
        });
    };

    socket.on('update', function(alertId) {
        requestUpdate();
        updateNotificationHandler.forEach(nh => nh(alertId));
    });

    socket.on('connect', function() {
        // get current alert on intial connect
        requestUpdate();
    });

    socket.on('disconnect', function() {
        $('body').addClass('disconnected');
    });

    socket.io.on('reconnect', function() {
        $('body').removeClass('disconnected');
        requestUpdate();
    });
};

module.exports = {
    initSocketIO,
    registerUpdateHandler,
    registerUpdateNotificationHandler,
    lastAction
};
