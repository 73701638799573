let login = require("./login.js");
let {initNavigation} = require("../modules/nav/nav.js");
const nativeApp = require("../modules/native-app/native-app.js");
let {initSocketIO} = require("./socket.js");
const notification = require("../modules/notification/notification");
const {addFullScreenShortcut, addLockModeShortcut, getAppConfig} = require('./lib');
const {setLockedMode} = require('./login');

const $ = window.$;

$.ajaxSetup({ cache: false });

const initBase = function(type) {
    login.checkAndDoRedirect();
    notification.init();
    initSocketIO();
    initNavigation();
    nativeApp.initMain(type);
};

$(function() {
    const appConfig = getAppConfig();
    const loginToken = login.getLoginToken();
    const persistentSession = login.getPersistentSessionData();
    if (loginToken !== "") {
        login.doLoginToken(loginToken, false, "", success => {
            if (success) {
                console.log("logged in with token");
            } else {
                console.log("Failed to login with token");
                login.resetSession();
            }
            initBase('token');
        });
    } else if (persistentSession) {
        login.doLoginPersistent(persistentSession.selector, persistentSession.validator, success => {
            if (success) {
                console.log("logged in with persistent session");
            } else {
                console.log("Failed to login with persistent session");
                login.resetSession();
            }
            initBase('persistent');
        });
    } else {
        login.checkLogin();
        initBase('default');
    }

    const getAnalyticsPage = function() {
        let page = window.location.pathname;
        // do not expose login code
        page = page.replace(/\/l\/[^#/?]+/i, '/l/<loginCode>');
        // add hash of current page
        page += window.location.hash;
        const name = encodeURIComponent(login.getShortLoginName());
        if (name !== "") {
            page += "#u:" + name;
        }
        const version = appConfig.version;
        if (version) {
            page += "#v:" + version;
        }
        return page;
    };

    /*eslint-disable */
    if (appConfig.matomoId !== "") {
        (function() {
            const u = '//'+appConfig.matomoUrl+'/';
            window._paq = [
                ["disableCookies"],
                ['setTrackerUrl', u+'matomo.php'],
                ['setSiteId', appConfig.matomoId]
            ];
            const d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
        function locationHashChanged() {
            window._paq.push(['setCustomUrl', getAnalyticsPage()]);
            window._paq.push(['trackPageView']);
        }

        window.onhashchange = locationHashChanged;
        locationHashChanged();
    }
    /*eslint-enable */

    window.nativeApp = nativeApp;

    addFullScreenShortcut();
    addLockModeShortcut(setLockedMode);

    window.setTimeout(function() {
        try {
            $('#action_map')[0].contentWindow.focus();
        } catch (e) {
            // ignore
        }
    }, 1000);

});