const Promise = require('promise-polyfill');
const login = require('../../main/login.js');

const $ = window.$;

const isActive = function() {
    return window.nativeAppRemote !== undefined;
};

let resolveIsActive;

const isActivePromise = new Promise(resolve => {
    resolveIsActive = resolve;
});

let initAttempts = 5;

const initMain = function(logintype) {

    if (isActive()) {

        console.log('Native app detected!');
        resolveIsActive(true);
        login.checkLoginCallback(function(data) {
            let loginStatus = data !== 0;
            window.nativeAppRemote.init(loginStatus, logintype);
        });

        $('#navAppConfig').show().click(function() {
            window.nativeAppRemote.openConfig();
        });

    } else {
        if (--initAttempts > 0) {
            window.setTimeout(() => {
                initMain(logintype);
            }, 500);
        } else {
            resolveIsActive(false);
            console.log('No native app found!');
        }
    }

};

const callNativeLink = function(data) {

    switch (data['app-command']) {

        case 'intent':
            window.nativeAppRemote.intent('android.intent.action.VIEW', data['app-uri']);
            break;

    }

};

const initMap = function() {

    if (isActive()) {

        $(document).on('click', 'a[data-app-command]', function(e) {
            e.preventDefault();
            callNativeLink({
                'app-command': $(this).data('appCommand'),
                'app-uri': $(this).data('appUri')
            });
        });

    }

};

const downloadPdf = function(uri) {

    window.nativeAppRemote.downloadPdf(uri);

};

const isServiceWorkerDisabled = function() {
    return window.nativeAppRemote && window.nativeAppRemote.isServiceWorkerDisabled && window.nativeAppRemote.isServiceWorkerDisabled() === true;
};

export {
    initMain,
    initMap,
    isActive,
    isActivePromise,
    callNativeLink,
    downloadPdf,
    isServiceWorkerDisabled
};