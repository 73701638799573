const template = require('./alertbox.pug');
const messagebox = require('./messagebox');

const open = function(title, message) {
    const content = template({message});
    const alertMessageBox = messagebox(title, content, true);
    alertMessageBox.open();
};

module.exports = {
    open
};