const statusConfig = require('../../../config.status');

const getStatusType = function(statusCode) {
    if (Array.isArray(statusConfig.types) && statusConfig.types.length) {
        const status = statusConfig.types.find(s => s.id === parseInt(statusCode));
        return status;
    }
    return null;
};

const getStatusColor = function(statusCode) {
    const statusType = getStatusType(statusCode);
    if (statusType) {
        return statusType.color;
    }
    return null;
};

module.exports = {
    getStatusType,
    getStatusColor
};