if (typeof Object.assign !== 'function') {
    Object.assign = function(target) {
        'use strict';
        if (target === null) {
            throw new TypeError('Cannot convert undefined or null to object');
        }

        target = Object(target);
        for (var index = 1; index < arguments.length; index++) {
            var source = arguments[index];
            if (source !== null) {
                for (var key in source) {
                    if (Object.prototype.hasOwnProperty.call(source, key)) {
                        target[key] = source[key];
                    }
                }
            }
        }
        return target;
    };
}

//Ensures there will be no 'console is undefined' errors
window.console = window.console || (function() {
    const c = {}; c.log = c.warn = c.debug = c.info = c.error = c.time = c.dir = c.profile = c.clear = c.exception = c.trace = c.assert = function() {};
    return c;
}());

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(search, replacement) {
    var that = this;
    return that.replace(new RegExp(search, 'g'), replacement);
};