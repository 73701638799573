const { createElement: h, h2, div, a, object, p, span, i} = require('preact-hyperscript');
const { render, Component } = require('preact');

class Overview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timestamp: Date.now(),
            recent: {},
            day: {},
            week: {}
        };
    }

    componentDidMount() {
        this.interval = window.setInterval(() => {
            if (document.querySelector('.container.overview') === null) {
                if (this.interval) {
                    window.clearInterval(this.interval);
                }
            } else {
                this.setState({
                    timestamp: Date.now()
                });
            }
        }, 60 * 1000);
    }

    render(_, {timestamp, recent, day, week}) {
        return div({className: 'container overview'}, [
            h2({}, "Aktuelle Übersicht der Einsätze"),
            p({}, "Legende:"),
            p({}, "Rot eingefärbte Gemeinde = In dieser Gemeinde war mindestens ein Einsatz."),
            p({}, "Blau eingefärbte Feuerwehr = Diese Feuerwehr wurde alarmiert."),
            p({}, "Durch Überfahren mit der Maus wird der Name der jeweiligen Gemeinde oder Feuerwehr angezeigt."),
            p({}, [
                h2({}, [
                    "Alarmierungen in den letzten 6 Stunden",
                    span({}, ' '),
                    a({href: "#navOverviewDetail6h"}, [i({className: 'fa fa-link'})])
                ]),
                object({type: 'image/svg+xml', height: recent.height, width: recent.width, data: `/overview?lastHours=6&_=${timestamp}`, onLoad: (x) => this.setState({recent: {height: x.path[0].clientHeight, width: x.path[0].clientWidth}})})

            ]),
            p({}, [
                h2({}, [
                    "Alarmierungen in den letzten 24 Stunden",
                    span({}, ' '),
                    a({href: "#navOverviewDetailDay"}, [i({className: 'fa fa-link'})])
                ]),
                object({type: 'image/svg+xml', height: day.height, width: day.width, data: `/overview?lastHours=24&_=${timestamp}`, onLoad: (x) => this.setState({day: {height: x.path[0].clientHeight, width: x.path[0].clientWidth}})})
            ]),
            p({}, [
                h2({}, [
                    "Alarmierungen in der letzten Woche",
                    span({}, ' '),
                    a({href: "#navOverviewDetailWeek"}, [i({className: 'fa fa-link'})])
                ]),
                object({type: 'image/svg+xml', height: week.height, width: week.width, data: `/overview?lastHours=168&_=${timestamp}`, onLoad: (x) => this.setState({week: {height: x.path[0].clientHeight, width: x.path[0].clientWidth}})})
            ])]
        );
    }
}

const start = function({container}) {
    return render(
        h(Overview, {}),
        container.get(0)
    );
};

module.exports = {
    template: () => "",
    start
};