import {load, save} from "./storage";

const lib = require("./lib.js");
const $ = window.$;

const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991; // IE11 Polyfill

//Permission Constants
const Permissions = {
    User: 0,
    Command: 1,
    Supervisor: 2,
    Admin: 3
};

const PermissionsInv = {};

for (const p in Permissions) {
    PermissionsInv[Permissions[p]] = p;
}

const setPermission = function(perm) {
    save("permission", perm);
};

const setLoginName = function(name) {
    save("name", name);
};

const setAllowStatus = function(allow) {
    save("allowStatus", allow);
};

const getAllowStatus = function() {
    return load("allowStatus");
};

const setEnableGPSTracking = function(enable) {
    save("enableGPSTracking", enable);
};

const getEnableGPSTracking = function() {
    return load("enableGPSTracking");
};

const getAlertTimeout = function() {
    return parseInt(load("alertTimeout")) || MAX_SAFE_INTEGER;
};

const setAlertTimeout = function(alertTimeout) {
    save("alertTimeout", alertTimeout);
};

const setSessionName = function(name) {
    if (name) {
        save("sessionName", name);
    } else {
        save("sessionName", "");
    }
};

const setLoginAsAdminName = function(name) {
    if (name) {
        save("loginAsAdminName", name);
    } else {
        save("loginAsAdminName", "");
    }
};

const isLoginAsMode = function() {
    if (load("loginAsAdminName")) {
        return true;
    }
    return false;
};

const getSessionName = function() {
    return load("sessionName");
};

const setPersistentSessionData = function(data) {
    if (data && data.validator && data.selector) {
        const {validator, selector} = data;
        save("persistentSession", JSON.stringify({validator, selector}), {expires: 365 * 3});
    } else {
        save("persistentSession", "");
    }
};

const getPersistentSessionData = function() {
    const data = load("persistentSession");
    if (data) {
        return JSON.parse(data);
    }
    return undefined;
};

const setGpsStatusActive = function(active) {
    save("isGpsStatusActive", active);
};

const getGpsStatusActive = function() {
    switch (load("isGpsStatusActive")) {
        case "true":
            return true;
        case "false":
            return false;
        default:
            return undefined;
    }
};

const setViewGPSLocations = function(active) {
    save("viewGPSLocations", active);
};

const getViewGPSLocations = function() {
    return load("viewGPSLocations") === '1';
};

const getLoginName = function() {
    const name = load("name");
    const sessionName = getSessionName();
    if (sessionName) {
        return name + " (" + sessionName + ")";
    } else {
        return name;
    }
};

const setDefaultCity = function(name) {
    save("defaultCity", name);
};

const getDefaultCity = function() {
    return load("defaultCity");
};

const setDefaultState = function(number) {
    load("defaultState", number);
};

const getDefaultState = function() {
    return load("defaultState");
};


const getShortLoginName = function() {
    let name = getLoginName();
    if (typeof name === 'string' || name instanceof String) {
        name = name.replace("Feuerwehr", "FW");
        name = name.replace("Kommando", "KDO");
        name = name.replace("Bergrettung", "BR");
    }
    return name;
};

const setSessionCookie = function(sid) {
    save("connect.sid", sid);
};

const isLoggedIn = function() {
    if (getLoginName() !== "") {
        return true;
    } else {
        return false;
    }
};

const getPermission = function() {
    if (!isLoggedIn()) {
        return -1;
    }
    return parseInt(load("permission"));
};

const resetSession = function(deletePersistentCookie = false) {
    setPermission(0);
    setLoginAsAdminName("");
    setLoginName("");
    setDefaultCity("");
    setDefaultState("");
    setSessionCookie("");
    setSessionName(false);
    setGpsStatusActive("");
    setViewGPSLocations("");
    setAlertTimeout("");

    if (deletePersistentCookie === true) {
        setPersistentSessionData(false);
    }
};

const updateGUIUserData = function() {
    if (isLoggedIn()) {
        $("#navUserContainer span.loginname").html(getShortLoginName());
        $("#navUserContainer span.loginname").attr('title', getLoginName());
        $("#navLogin span.actionname").html("Logout");
    } else {
        $("#navLogin span.actionname").html("Login");
    }
};

const checkLoginCallback = function(callback) {
    $.ajax({
        type: "GET",
        url: "/checkLogin",
        dataType: 'json',
        async: false,
        data: { rand: new Date().getTime() },
        success: callback
    });
};

const checkLogin = function() {
    checkLoginCallback(function (data) {
        if (data !== 0) {
            // active login
            updateGUIUserData();
        } else {
            // no login
            if (isLoggedIn()) {
                // reset login
                resetSession();
                document.location.reload(true);
            }
        }
    });
};

const logout = function () {
    resetSession();
    $.ajax({
        type: "POST",
        url: "/logout",
        data: {},
        success: function() {
            window.location = window.location.href.replace(/\/l\/[^/?]+\/?/, "").split("#").shift();
        },
        error: function() {
        }
    });
};

const doLogoutAs = function () {
    resetSession();
    $.ajax({
        type: "POST",
        url: "/logoutAsUser",
        data: {},
        success: function(data) {
            setSessionName(data.persistent && data.persistent.sessionName);
            setLoginName(data.realname);
            setPermission(data.permission);
            setDefaultCity(data.city);
            setDefaultState(data.state);
            setAllowStatus(data.allowStatus);
            setEnableGPSTracking(data.enableGPSTracking);
            setViewGPSLocations(data.viewGPSLocations);
            setAlertTimeout(data.alertTimeout);

            updateGUIUserData();
            window.location = window.location.href.replace(/\/l\/[^/?]+\/?/, "").split("#").shift();
        },
        error: function() {
        }
    });
};

const checkAndDoRedirect = function() {
    const redirect = lib.getParameterByName("redirect");
    if (redirect !== "") {
        window.location = "/" + redirect;
    }
};

const getLoginToken = function() {
    let token = document.location.pathname.match(/\/l\/([^\/?]*)/);
    if (token && token[1]) {
        return token[1];
    }
    token = lib.getParameterByName("loginToken");

    return token;
};

const doLoginPersistent = function(selector, validator, callback) {

    const postData = {selector, validator};

    $.ajax({
        type: "POST",
        url: "/login",
        data: postData,
        success: function(data) {
            setLoginAsAdminName(data.loginAsBaseUser && data.loginAsBaseUser.realname);
            setSessionName(data.persistent.sessionName);
            setLoginName(data.realname);
            setPermission(data.permission);
            setDefaultCity(data.city);
            setDefaultState(data.state);
            setAllowStatus(data.allowStatus);
            setEnableGPSTracking(data.enableGPSTracking);
            setViewGPSLocations(data.viewGPSLocations);
            setAlertTimeout(data.alertTimeout);

            updateGUIUserData();
            callback(true);
        },
        error: function() {
            callback(false);
        }
    });
};

const isLockedMode = function() {
    return load("lockedMode") === 'true';
};

const setLockedMode = function(enabled) {
    if (enabled === undefined) {
        load("lockedMode", !isLockedMode());
    } else {
        load("lockedMode", enabled);
    }

};

const doLoginToken = function(token, persistent, sessionName, callback) {

    if (token === "") {
        callback(false);
    } else {

        const postData = {
            token: token,
            persistent: persistent,
            sessionName: sessionName
        };

        $.ajax({
            type: "POST",
            url: "/login",
            data: postData,
            success: function(data) {
                if (data.realname !== getLoginName()) {
                    setLoginAsAdminName(data.loginAsBaseUser && data.loginAsBaseUser.realname);
                    setPersistentSessionData(data.persistent);
                    setSessionName(data.persistent && data.persistent.sessionName);
                    setLoginName(data.realname);
                    setPermission(data.permission);
                    setDefaultCity(data.city);
                    setDefaultState(data.state);
                    setAllowStatus(data.allowStatus);
                    setEnableGPSTracking(data.enableGPSTracking);
                    setViewGPSLocations(data.viewGPSLocations);
                    setAlertTimeout(data.alertTimeout);
                }
                updateGUIUserData();
                callback(true);
            },
            error: function() {
                callback(false);
            }
        });

    }
};

const doLoginAs = function(userId) {

    const postData = {
        userId
    };

    $.ajax({
        type: "POST",
        url: "/loginAsUser",
        data: postData,
        success: function(data) {
            setLoginAsAdminName(data.loginAsBaseUser && data.loginAsBaseUser.realname);
            setPersistentSessionData(data.persistent);
            setSessionName(data.persistent && data.persistent.sessionName);
            setLoginName(data.realname + ' via ' + data.loginAsBaseUser.realname);
            setPermission(data.permission);
            setDefaultCity(data.city);
            setDefaultState(data.state);
            setAllowStatus(data.allowStatus);
            setEnableGPSTracking(data.enableGPSTracking);
            setViewGPSLocations(data.viewGPSLocations);
            setAlertTimeout(data.alertTimeout);
            // reload start page
            window.location = window.location.href.split("#").shift();
        },
        error: function() {
            alert("Password falsch."); // eslint-disable-line no-alert
        }
    });
};

const doLogin = function(username, password, persistent, sessionName) {

    const postData = {
        username,
        password,
        persistent,
        sessionName
    };

    $.ajax({
        type: "POST",
        url: "/login",
        data: postData,
        success: function(data) {
            setLoginAsAdminName(data.loginAsBaseUser && data.loginAsBaseUser.realname);
            setPersistentSessionData(data.persistent);
            setSessionName(data.persistent && data.persistent.sessionName);
            setLoginName(data.realname);
            setPermission(data.permission);
            setDefaultCity(data.city);
            setDefaultState(data.state);
            setAllowStatus(data.allowStatus);
            setEnableGPSTracking(data.enableGPSTracking);
            setViewGPSLocations(data.viewGPSLocations);
            setAlertTimeout(data.alertTimeout);
            // reload start page
            window.location = window.location.href.split("#").shift();
        },
        error: function() {
            alert("Password falsch."); // eslint-disable-line no-alert
        }
    });
};

const getUserConfig = function() {
    let config = lib.getParameterByName("c");
    if (config) {
        return lib.parseCrappyJson(config);
    }
    config = load('userConfig');
    if (config) {
        return lib.parseCrappyJson(decodeURIComponent(config));
    }
    return "";
};

const resetUserConfig = function() {
    save('userConfig', "");
    let href = document.location.href;
    href = href.split("#")[0];
    href = lib.removeURLParameter(href, 'c');
    document.location = href;
};

const setUserConfig = function(userConfig, forceReload = true) {
    const crappyUserConfig = lib.createCrappyJson(userConfig);
    let href = document.location.href;
    href = href.split("#")[0];
    href = lib.removeURLParameter(href, 'c');
    if (getLoginToken()) {
        if (href.indexOf("?") !== -1) {
            if (href.slice(-1) !== "?" && href.slice(-1) !== "&") {
                href += "&";
            }
            href += "c=" + crappyUserConfig;
        } else {
            href += "?c=" + crappyUserConfig;
        }
        document.location = href;
    } else {
        save('userConfig', crappyUserConfig, {expires: 365 * 3});
        if (forceReload) {
            document.location = href;
        }
    }
};

export {
    setSessionName,
    getSessionName,
    getPersistentSessionData,
    getAllowStatus,
    getEnableGPSTracking,
    doLoginPersistent,
    getLoginToken,
    checkAndDoRedirect,
    logout,
    doLogin,
    checkLogin,
    checkLoginCallback,
    isLoggedIn,
    getPermission,
    resetSession,
    doLoginToken,
    getShortLoginName,
    getLoginName,
    Permissions,
    PermissionsInv,
    getDefaultCity,
    getDefaultState,
    getUserConfig,
    setUserConfig,
    resetUserConfig,
    doLoginAs,
    isLoginAsMode,
    doLogoutAs,
    updateGUIUserData,
    setGpsStatusActive,
    getGpsStatusActive,
    setViewGPSLocations,
    getViewGPSLocations,
    isLockedMode,
    setLockedMode,
    getAlertTimeout
};


