const $ = window.$;

function save(key, data, options = {}) {
    if (!options.sameSite) {
        options.sameSite = 'None';
    }
    if (location.protocol === 'https:') {
        options.secure = true;
    }
    return $.cookie(key, data, options);
}

function load(key) {
    return $.cookie(key);
}

export {
    save,
    load
};
