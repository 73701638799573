const {initServiceWorker, removeAllServiceWorker} = require("./modules/offline-cache/offline-cache.js");
require('./main/extensions.js');
require("./main/polyfills.js");
require("./main/bootstrap.js");
require("./main/native-app-legacy.js");
const {isActivePromise, isServiceWorkerDisabled} = require('./modules/native-app/native-app');
window.nativeAppApi = require("./modules/native-app/native-app-api.js");
isActivePromise.then(() => {
    if (isServiceWorkerDisabled()) {
        removeAllServiceWorker();
    } else {
        initServiceWorker();
    }
});
console.log("app started! :)");