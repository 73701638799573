const $ = window.$;
const {updateMap} = require('../map/map.js');
const alertDetails = require('../../modules/alert-details/alert-details');

const template = require('./helicopter.pug');

const start = function() {
    updateMap({selector: '#detail_map', c: "{map:{tl:opho,z:18,ad:0,om:0}}"});
    updateMap({selector: '#overview_map', c: "{map:{ov:1,ad:0,om:0}}"});
    window.setTimeout(function() {
        $('#detail_map')[0].contentWindow.focus();
    }, 1000);
};

module.exports = {
    template: () => template({alertDetails: alertDetails({alertId: 'last'})}),
    start
};