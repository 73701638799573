module.exports = {
    enabled: true,
    preventWrongStatus: true,
    types: [
        {
            id: 1,
            name: 'Einsatzbereit über Funk',
            color: '#ABBF0F',
            following: [1,2,3,6,11]
        },
        {
            id: 2,
            name: 'Einsatzbereit auf der Wache',
            color: '#ABBF0F',
            following: [1,2,3,6,11]
        },
        {
            id: 3,
            name: 'Einsatzauftrag übernommen',
            color: '#F27B13',
            incidentDependent: true,
            following: [1,2,3,4,5,11]
        },
        {
            id: 4,
            name: 'Am Einsatzort (einsatzbereit)',
            color: '#04BFBF',
            incidentDependent: true,
            following: [1,3,4,5,11]
        },
        {
            id: 5,
            name: 'Am Einsatzort (im Einsatz)',
            color: '#04BFBF',
            incidentDependent: true,
            following: [1,2,3,5,11]
        },
        {
            id: 6,
            name: 'Nicht einsatzbereit',
            color: '#F23D4C',
            following: [1,2,6,11],
            openResourceNotes: true
        },
        {
            id: 7,
            name: 'Einrücken',
            following: [1,2,3,6,7,11],
            hidden: true
        },
        {
            id: 8,
            name: 'Einrücken (bed.)',
            following: [1,2,6,8,11],
            hidden: true
        },
        {
            id: 11,
            name: 'Sprechwunsch',
            following: [1,2,3,4,5,6,11]
        }
    ] 
};