.textpresetsedit
    input(type="hidden" name="id" value=textpreset.id)
    div
        b Titel:
        br
        input(type="text" name="title" value=textpreset.title)
    div
        b Typ:
        br
        select(name="type")
            each t in typeList
                option(value=t.id selected=textpreset.type===t.id) #{t.name}
    div
        b Text:
        br
        textarea(name="text")=textpreset.text