const config = window.config;
const userConfigStandard = config.map.userconfig.standard;
const {getUserConfig} = require("../main/login.js");
const deepCopy = (o) => JSON.parse(JSON.stringify(o));

const getDefaultConfig = function(dataLayers = null) {
    const standard = deepCopy(userConfigStandard);

    if (dataLayers !== null) {
        for (let i in dataLayers) {
            let dataLayer = dataLayers[i];
            standard.map.dl[dataLayer.shortCode] = dataLayer.display.toString();
        }
    }

    return standard;
};

const convertTileLayerPreset = function (tl) {
    const preset = config.map.presets.filter(p => p.key === tl);
    if (preset && preset[0]) {
        return {
            tl: preset[0].tl || "0",
            ol: preset[0].ol || "0"
        };
    }
    return {};
};

const parseConfig = function() {

    const standard = getDefaultConfig();

    for (let i in config.map.dataLayers) {
        const dataLayer = config.map.dataLayers[i];
        standard.map.dl[dataLayer.shortCode] = dataLayer.display.toString();
    }
    Object.assign(standard.map, convertTileLayerPreset(standard.map.tl));

    let userConfig = getUserConfig();

    if (userConfig === "") {
        return standard;
    }
    try {
        if (userConfig !== undefined) {
            if (userConfig.map !== undefined) {
                if (userConfig.map.tl !== undefined) {
                    Object.assign(standard.map, convertTileLayerPreset(userConfig.map.tl));
                }
                if (userConfig.map.z !== undefined) {
                    standard.map.z = userConfig.map.z;
                }
                if (userConfig.map.lm !== undefined) {
                    standard.map.lm = userConfig.map.lm;
                }
                if (userConfig.map.az !== undefined) {
                    switch (userConfig.map.az) {
                        case "2":
                        case "1":
                        default:
                            standard.map.az = "1";
                            break;
                        case "0":
                            standard.map.az = "0";
                            break;
                    }
                }
                if (userConfig.map.gps !== undefined) {
                    standard.map.gps = userConfig.map.gps;
                }
                if (userConfig.map.sta !== undefined) {
                    standard.map.sta = userConfig.map.sta;
                }
                if (userConfig.map.fgi !== undefined) {
                    if (userConfig.map.fgi.split) {
                        standard.map.fgi = userConfig.map.fgi.split("-").map(v => parseInt(v));
                    } else {
                        standard.map.fgi = userConfig.map.fgi;
                    }
                }
                if (userConfig.map.zb !== undefined) {
                    standard.map.zb = userConfig.map.zb;
                }
                if (userConfig.map.cz !== undefined) {
                    standard.map.cz = userConfig.map.cz;
                }
                if (userConfig.map.ob !== undefined) {
                    standard.map.ob = userConfig.map.ob;
                }
                if (userConfig.map.pb !== undefined) {
                    standard.map.pb = userConfig.map.pb;
                }
                if (userConfig.map.dlz !== undefined) {
                    standard.map.dlz = userConfig.map.dlz;
                }
                if (userConfig.map.ad !== undefined) {
                    standard.map.ad = userConfig.map.ad;
                }
                if (userConfig.map.ov !== undefined) {
                    standard.map.ov = userConfig.map.ov;
                }
                if (userConfig.map.om !== undefined) {
                    switch (userConfig.map.om) {
                        case "0":
                        case "1":
                        default:
                            standard.map.om = "1";
                            break;
                        case "2":
                            standard.map.om = "0";
                            break;
                    }
                }
                if (userConfig.map.dl !== undefined) {
                    for (const shortCode in userConfig.map.dl) {
                        standard.map.dl[shortCode] = userConfig.map.dl[shortCode];
                    }
                }

            }
            if (userConfig.iw !== undefined) {
                standard.iw = userConfig.iw;
            }
            if (userConfig.at !== undefined) {
                standard.at = userConfig.at;
            }
            if (userConfig.qsw !== undefined) {
                standard.qsw = userConfig.qsw;
            }
            if (userConfig.hp !== undefined) {
                standard.hp = userConfig.hp;
            }
        }
        return standard;
    } catch (e) {
        console.log("Error config string: " + e);
        return standard;
    }
};

export {
    parseConfig,
    getDefaultConfig
};
