div(style='padding: 20px; position: relative;')
    h2
        | #{vars.projectName} Departments
        a#addNewLink.btn.btn-success.btn-xs(href='#navAdminDepartments', style='margin-left: 15px;')
            i.fa.fa-plus
            |  Neues Department hinzufügen
    #listFilter
        .form-group
            .input-group
                span.input-group-addon
                    i.fa.fa-search
                input#departmentlist-search.form-control(type='text', placeholder='Suche...')
    #listContainer
        table#departments-table.listing
            thead
                tr#headerRow
                    th Name
                    th(style='width: 220px') Ort
                    th(style='width: 170px') Organisations-ID
                    th(style='width: 80px')
            tbody
    #modal-preset.modal.fade(tabindex='-1', role='dialog')
        .modal-dialog(role='document')
            .modal-content
                .modal-header
                    button.close(type='button', data-dismiss='modal', aria-label='Close')
                        span(aria-hidden='true') ×
                    h4.modal-title Titel
                .modal-body Inhalt
                .modal-footer
                    button.btn.btn-success(type='button') Speichern
    #loadingScreen(style='display: none;')
        i.fa.fa-cog.fa-spin.fa-3x.fa-fw
